import React, { useRef, useState, useContext, useEffect } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { OrbitControls, Stars, PerspectiveCamera } from '@react-three/drei';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { EffectComposer, Bloom } from '@react-three/postprocessing';
import Globe from './Globe';
import WireframeSphere from './WireframeSphere';
import { GlobalContext } from '../../contexts/GlobalStore';
import * as THREE from 'three';

const worlds = [
  {
    name: "Triber World",
    brand: "by TRIBER STUDIOS!",
    position: [0, 0, 0],
    component: <Globe />,
  },
  {
    name: "V2 World (Soon)",
    brand: "by V2",
    position: [20, 0, 0],
    component: <WireframeSphere color="white" />,
  },
  {
    name: "Beloved. World (Soon)",
    brand: "by Beloved. From Now On",
    position: [40, 0, 0],
    component: <WireframeSphere color="#BF8EE8" />,
  },
  {
    name: "Ajaar World (Soon)",
    brand: "by Ajaar",
    position: [60, 0, 0],
    component: <WireframeSphere color="#F93235" />,
  },
];

const WorldViewer = ({ currentIndex }) => {
  const cameraRef = useRef();
  const controlsRef = useRef();
  const { camera } = useThree();

  useEffect(() => {
    // Update camera position when the currentIndex changes
    const targetPosition = new THREE.Vector3(
      worlds[currentIndex].position[0],
      worlds[currentIndex].position[1],
      7 // Keep the z-position constant
    );

    const initialPosition = camera.position.clone();
    const lerpSpeed = 0.02; // Adjust for smoother or faster transitions
    let lerpAlpha = 0;

    function animateCamera() {
      lerpAlpha += lerpSpeed;
      if (lerpAlpha > 1) lerpAlpha = 1;
      camera.position.lerpVectors(initialPosition, targetPosition, lerpAlpha);

      // Update the controls target to look at the current world
      if (controlsRef.current) {
        controlsRef.current.target.lerp(
          new THREE.Vector3(worlds[currentIndex].position[0], worlds[currentIndex].position[1], 0),
          lerpAlpha
        );
        controlsRef.current.update(); // Ensure the controls are updated
      }

      if (lerpAlpha < 1) {
        requestAnimationFrame(animateCamera);
      }
    }

    animateCamera();
  }, [currentIndex, camera]);

  return (
    <>
      <OrbitControls ref={controlsRef} enablePan={false} enableZoom={false} />
      <Stars radius={100} depth={50} count={5000} factor={8} saturation={0} fade />
      <ambientLight />
      <group>
        {worlds.map((world, index) => (
          <React.Fragment key={index}>
            <group position={world.position}>{world.component}</group>
          </React.Fragment>
        ))}
      </group>
      <EffectComposer>
        <Bloom intensity={0.7} kernelSize={2} luminanceThreshold={0.85} luminanceSmoothing={0.05} mipmapBlur={true} />
      </EffectComposer>
    </>
  );
};

const WorldsView = () => {
  const { isMobileView } = useContext(GlobalContext);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex < worlds.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const styles = {
    container: {
      position: 'relative',
      width: '100%',
      height: '100svh',
      background: '#1f1f1f',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      overflow: 'hidden',
      paddingTop: 'env(safe-area-inset-top)',
      paddingBottom: 'env(safe-area-inset-bottom)',
      padding: '24px',
      gap: '16px',
    },
    title: {
      color: 'white',
      fontFamily: 'Work Sans',
      fontSize: isMobileView ? '24px' : '32px',
      fontWeight: '500',
    },
    contentWrapper: {
      width: '100%',
      flex: '1',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '4px',
    },
    worldContainer: {
      width: '100%',
      maxWidth: '1000px',
      height: '100%',
      background: 'black',
      borderRadius: '24px',
      border: '2px solid rgba(255, 255, 255, 0.4)',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    arrowButton: {
      background: 'none',
      border: 'none',
      color: 'white',
      fontSize: '2rem',
      cursor: 'pointer',
      width: '32px',
      height: '32px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0px',
    },
    arrowButtonDisabled: {
      background: 'none',
      border: 'none',
      color: 'grey',
      fontSize: '2rem',
      cursor: 'default',
      width: '32px',
      height: '32px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0px',
    },
    contentBox: {
      background: 'rgba(28, 28, 28, 1)',
      color: 'white',
      padding: '8px 24px',
      borderRadius: '10px',
      textAlign: 'center',
      fontFamily: 'Work Sans',
      fontSize: '16px',
      border: '1px solid rgba(255, 255, 255, 0.1)',
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
    },
    worldName: {
      fontFamily: 'Work Sans',
      fontSize: '20px',
      fontWeight: '500',
    },
    brandName: {
      fontFamily: 'Work Sans',
      fontSize: '16px',
      fontWeight: '400',
    },
  };

  return (
    <div
      style={{
        ...styles.container,
        height: isMobileView ? 'calc(100svh - 124px)' : 'calc(100vh - 64px)',
      }}
    >
      <div style={styles.title}>Worlds View</div>
      <div style={styles.contentWrapper}>
        <button
          style={currentIndex === 0 ? styles.arrowButtonDisabled : styles.arrowButton}
          onClick={handlePrev}
          disabled={currentIndex === 0}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <div style={styles.worldContainer}>
          <Canvas>
            <WorldViewer currentIndex={currentIndex} />
          </Canvas>
        </div>
        <button
          style={currentIndex === worlds.length - 1 ? styles.arrowButtonDisabled : styles.arrowButton}
          onClick={handleNext}
          disabled={currentIndex === worlds.length - 1}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      <div style={styles.contentBox}>
        <div style={styles.worldName}>{worlds[currentIndex].name}</div>
        <div style={styles.brandName}>{worlds[currentIndex].brand}</div>
      </div>
    </div>
  );
};

export default WorldsView;
