import React, { useState, useEffect } from "react";
import ContentWrapper from "../ContentWrapper";
import HappeningNow from "./HappeningNow/HappeningNow";
import Upcoming from "./Upcoming/Upcoming";
import { GlobalContext } from "../../contexts/GlobalStore";

const EventsContent = () => {
  const { isMobileView } = React.useContext(GlobalContext);
  const [activeTab, setActiveTab] = useState("Happening Now");
  const tabs = [
    { id: "Happening Now", header: "Happening Now" },
    { id: "Upcoming", header: "Upcoming" },
  ];

  // New event data array
  const eventData = [
    { 
      title: 'Opening Exhibition', 
      spaceName: 'Triber Gallery',
      worldName: "Triber World", 
      image: '/assets/TriberGalleryFlyer.png', 
      url: 'https://triberworld.triber.space',
      status: 'upcoming'
    },
    // Add more events here...
  ];

  const currentEvents = eventData.filter(event => event.status === 'current');
  const upcomingEvents = eventData.filter(event => event.status === 'upcoming');

  useEffect(() => {
    if (currentEvents.length === 0) {
      setActiveTab("Upcoming");
    }
  }, []);

  const handleToggle = (id) => {
    setActiveTab(id);
    console.log("TAB", activeTab);
  };

  const handleViewUpcoming = () => {
    setActiveTab("Upcoming");
  };

  return (
    <ContentWrapper gap="24px">
      <div style={{ display: "flex", gap: "16px" }}>
        {tabs.map((tab, index) => (
          <span
            key={index}
            onClick={() => handleToggle(tab.id)}
            style={{
              fontFamily: "Work Sans",
              fontSize: isMobileView ? "20px" : "24px",
              fontWeight: 600,
              color: activeTab === tab.id ? "rgb(255, 255, 255)" : "grey",
              lineHeight: isMobileView ? "20px" : "24px",
              textAlign: "left",
              display: "block",
              letterSpacing: "-0.2px",
              width: "unset",
              height: "unset",
              flexShrink: 0,
              position: "relative",
              padding: "0px",
              whiteSpace: "pre-wrap",
              cursor: "pointer",
            }}
          >
            {tab.header}
          </span>
        ))}
      </div>

      {activeTab === "Happening Now" ? (
        <HappeningNow events={currentEvents} onViewUpcoming={handleViewUpcoming} />
      ) : (
        <Upcoming events={upcomingEvents} />
      )}
    </ContentWrapper>
  );
};

export default EventsContent;
