import React, { useState, useEffect } from 'react';
import ContentWrapper from '../ContentWrapper';
import DiscoverContent from './DiscoverContent';
import MarketMenu from "./MarketMenu";
import ProductContent from './ProductContent';
import CartContent from './CartContent';
import OrderConfirmation from './OrderConfirmation';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

const MarketContainer = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const [historyStack, setHistoryStack] = useState(['/']);

  useEffect(() => {
    // Add the current location to the history stack on each location change
    setHistoryStack(prevStack => {
      const newStack = [...prevStack];
      if (newStack[newStack.length - 1] !== location.pathname) {
        newStack.push(location.pathname);
        console.log('Updated History Stack:', newStack);
      }
      return newStack;
    });
  }, [location.pathname]);

  const handleBackClick = () => {
    // Navigate back if there are more than one item in the history stack
    if (historyStack.length > 1) {
      setHistoryStack(prevStack => {
        const newStack = [...prevStack];
        newStack.pop(); // Remove the current location
        const newPath = newStack[newStack.length - 1];
        console.log('Navigating to:', newPath);
        navigate(newPath); // Navigate to the previous location
        return newStack;
      });
    }
  };

  const handleTabClick = () => {
    navigate('/market/cart');
    console.log('Navigating to /market/cart');
  };

  const pathSegments = location.pathname.split('/').filter(Boolean);
  const activeTab = pathSegments.length > 1 ? pathSegments[1] : 'Discover'; // Assuming 'Discover' is the default tab
  const isCheckoutPage = location.pathname.includes('order-confirmation');

  return (
    <ContentWrapper width={"100%"} gap={"32px"}>
      {!isCheckoutPage && (
        <MarketMenu
          width={"100%"}
          onTabClick={handleTabClick}
          marketTab={activeTab}
          onBackClick={handleBackClick}
          showBackButton={location.pathname !== '/' && location.pathname !== '/market'}
        />
      )}
      <Routes>
        <Route index element={<DiscoverContent width={"100%"} />} />
        <Route path="product/:productId" element={<ProductContent width={"100%"}/>} />
        <Route path="cart" element={<CartContent width={"100%"}/>} />
        <Route path="order-confirmation" element={<OrderConfirmation width={"100%"}/>} />
      </Routes>
    </ContentWrapper>
  );
};

export default MarketContainer;
