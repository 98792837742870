import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './ProfileMenu.css';
import { faCog, faPerson, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { signOut } from '@aws-amplify/auth';
import { GlobalContext } from '../../contexts/GlobalStore';
import { CSSTransition } from 'react-transition-group';

const ProfileMenu = ({ closeMenu, isVisible }) => {
  const { currentUserProfile } = useContext(GlobalContext);
  const navigate = useNavigate();

  const profileImage = currentUserProfile?.Avatar;
  const username = currentUserProfile?.username || 'Unknown User';
  const tribePoints = currentUserProfile?.totalPoints || 0;

  const handleProfileSettingsClick = () => {
    navigate('/Profile');
    closeMenu(); // Close the menu after navigation
  };

  const handleAvatarClick = () => {
    navigate('/Avatar');
    closeMenu(); // Close the menu after navigation
  };

  const handleSignOutClick = () => {
    signOut();
    closeMenu(); // Close the menu after sign out
  };

  return (
    <CSSTransition
      in={isVisible}
      timeout={300}
      classNames="dropdown"
      unmountOnExit
    >
    <div className="profile-menu">
      <div className="profile-header">
        {profileImage ? (
          <img src={profileImage} alt="Profile Avatar" className="profile-image" />
        ) : (
          <div className="profile-image-placeholder"></div>
        )}
        <div className="profile-menu-username">@{username}</div>
        <div className="points">{tribePoints} TP</div>
      </div>
      <div className="menu-divider"></div>
      <div className="menu-item" onClick={handleProfileSettingsClick}>
        <FontAwesomeIcon icon={faCog} fixedWidth size="lg" />
        <span className="menu-item-text">Profile Settings</span>
      </div>
      <div className="menu-item" onClick={handleAvatarClick}>
        <FontAwesomeIcon icon={faPerson} fixedWidth size="lg" />
        <span className="menu-item-text">Customize Avatar</span>
      </div>
      <div className="menu-divider"></div>
      <div className="menu-item" onClick={handleSignOutClick}>
        <FontAwesomeIcon icon={faSignOut} fixedWidth size="lg" />
        <span className="menu-item-text">Sign Out</span>
      </div>
    </div>
    </CSSTransition>
  );
};

export default ProfileMenu;
