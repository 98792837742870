import React, { useRef, useState, useContext, useEffect } from 'react';
import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { GlobalContext } from '../../contexts/GlobalStore';
import { updateProfile } from '../../graphql/mutations';
import { generateClient } from 'aws-amplify/api';
import { EffectComposer, Bloom } from '@react-three/postprocessing';

const client = generateClient();

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '24px',
    backgroundColor: '#1f1f1f',
  },
  title: {
    color: 'white',
    fontFamily: 'Work Sans',
    fontSize: '32px',
    fontWeight: '500',
  },
  avatarContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: '#e0e0e0',
    borderRadius: '24px',
    marginTop: '24px',
    overflow: 'hidden',
    maxWidth: '520px',
    border: '1px solid rgba(255, 255, 255, 0.3)',
  },
  sliderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '24px',
  },
  label: {
    color: 'white',
    fontFamily: 'Work Sans',
    fontSize: '16px',
    marginRight: '16px',
  },
  slider: {
    width: '36px',
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  saveButton: {
    backgroundColor: '#d9e4ec',
    color: 'black',
    fontFamily: 'Work Sans',
    fontSize: '20px',
    fontWeight: '500',
    padding: '8px 80px',
    border: 'none',
    borderRadius: '24px',
    marginTop: '24px',
    marginBottom: '24px',
    cursor: 'pointer',
    opacity: 0.5,
    pointerEvents: 'none',
  },
  activeSaveButton: {
    backgroundColor: '#d9e4ec',
    color: 'black',
    fontFamily: 'Work Sans',
    fontSize: '20px',
    fontWeight: '500',
    padding: '8px 80px',
    border: 'none',
    borderRadius: '24px',
    marginTop: '24px',
    marginBottom: '24px',
    cursor: 'pointer',
    opacity: 1,
    pointerEvents: 'auto',
  },
};

const Avatar = ({ color, animation }) => {
  const { scene, animations } = useGLTF('/assets/TriberCharacterThinner.glb');
  const [mixer] = useState(() => new THREE.AnimationMixer(scene));

  const { gl } = useThree();

  useEffect(() => {
    gl.setClearColor('#222222'); // Set the background color to dark gray
  }, [gl]);

  useEffect(() => {
    if (mixer && animations.length > 0) {
      const action = mixer.clipAction(animations[0]);
      action.play();
    }
  }, [animations, mixer]);

  useEffect(() => {
    if (scene) {
      scene.traverse((child) => {
        if (child.isMesh) {
          child.material.color.set(color);
          child.material.emissive.set(color);
          child.material.emissiveIntensity = 115;
        }
      });
    }
  }, [color, scene]);

  useFrame((state, delta) => {
    mixer.update(delta);
  });

  return <primitive object={scene} />;
};

const AvatarEditor = () => {
  const { currentUserProfile, setCurrentUserProfile } = useContext(GlobalContext);
  const [color, setColor] = useState('#ffffff'); // Default color white
  const [initialColor, setInitialColor] = useState('#ffffff'); // To track the original color
  const [isColorChanged, setIsColorChanged] = useState(false); // To track if the color has been changed

  useEffect(() => {
    if (currentUserProfile) {
      const userColor = currentUserProfile.baseAvatarColor || '#ffffff';
      setColor(userColor);
      setInitialColor(userColor);
    }
  }, [currentUserProfile]);

  const handleChangeColor = (event) => {
    const newColor = event.target.value;
    setColor(newColor);
    setIsColorChanged(newColor !== initialColor); // Enable the save button only if the color is changed
  };

  const handleSave = async () => {
    if (isColorChanged) {
      try {
        const result = await client.graphql({
          query: updateProfile,
          variables: {
            input: {
              id: currentUserProfile.id,
              baseAvatarColor: color,
            },
          },
        });

        setCurrentUserProfile(result.data.updateProfile);
        setInitialColor(color); // Update the initial color to the new saved color
        setIsColorChanged(false); // Disable the save button after saving
        console.log('Color saved:', color);
      } catch (error) {
        console.error('Error saving color:', error);
      }
    }
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Avatar Editor</h1>
      <div style={styles.avatarContainer}>
        <Canvas camera={{ position: [0, 3.5, 7]}}>
          <OrbitControls enablePan={false} enableZoom={false} target={[0, 3.5, 0]}/>
          <ambientLight intensity={3} />
          <Avatar color={color} animation="Idle" />
          <EffectComposer>
            <Bloom
              intensity={1} // Adjust intensity as needed
              kernelSize={3} // Use LARGE for a more pronounced effect
              luminanceThreshold={0.85}
              luminanceSmoothing={0.05}
              mipmapBlur={true}
            />
          </EffectComposer>
        </Canvas>
      </div>
      <div style={styles.sliderWrapper}>
        <label style={styles.label}>Accent Color:</label>
        <input
          style={styles.slider}
          type="color"
          value={color}
          onChange={handleChangeColor}
        />
      </div>
      <button 
        style={isColorChanged ? styles.activeSaveButton : styles.saveButton} 
        onClick={handleSave}
      >
        Save
      </button>
    </div>
  );
};

export default AvatarEditor;
