import React, { useRef, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { useGLTF } from '@react-three/drei';
import Marker from './Marker';
import * as THREE from 'three';

const Globe = () => {
    const gltf = useGLTF('/assets/tribeGlobeTest2.glb');
    const meshRef = useRef();
    const markerGroupRef = useRef(new THREE.Group());

    // Handle the material customization and rotation
    useFrame(() => {
        if (meshRef.current) {
            meshRef.current.rotation.y += 0.0001;
            let wireframe = meshRef.current.getObjectByName('Wireframe');
            if (wireframe) {
                wireframe.rotation.y += 0.0002;
            }
        }
    });

    useEffect(() => {
        if (meshRef.current) {
          const continents = meshRef.current.getObjectByName('Continents');
          if (continents) {
            const material = continents.material;
    
            // Modify the base color
            material.color = new THREE.Color(0xBAEAF2); // Green, for example
    
            // Modify the emission color and intensity
            material.emissive = new THREE.Color(0xBAEAF2); // Green emission color
            material.emissiveIntensity = 15; // Adjust emissive intensity as needed
          }
        }
      }, [gltf]); // Runs this effect once the GLTF is loaded

    useEffect(() => {
        if (meshRef.current) {
            console.log('Globe mounted');

            // Clean up only the marker groups, preserving planet objects
            const existingMarkers = meshRef.current.children.filter(
                child => child.userData.type === 'marker'
            );

            console.log(`Found ${existingMarkers.length} existing markers to remove`);

            existingMarkers.forEach(marker => {
                meshRef.current.remove(marker);
                console.log(`Removed marker: ${marker.uuid}`);
            });

            // Add new marker group
            markerGroupRef.current.userData.type = 'marker';
            markerGroupRef.current.name = 'MarkerGroup';
            meshRef.current.add(markerGroupRef.current);

            console.log(`Added new marker group: ${markerGroupRef.current.uuid}`);
            console.log("MeshRef after adding marker group:", meshRef.current);
        }

        // Clean up logic when the component unmounts
        return () => {
            if (meshRef.current) {
                const markersToRemove = meshRef.current.children.filter(
                    child => child.userData.type === 'marker'
                );

                markersToRemove.forEach(marker => {
                    meshRef.current.remove(marker);
                    console.log(`Clean up - Removed marker group: ${marker.uuid}`);
                });
            }
        };
    }, [gltf]);

    return (
        <primitive ref={meshRef} object={gltf.scene} scale={2.5} rotation={[0, Math.PI + 1.1, 0]}>
            {/* <Marker
                parentRef={markerGroupRef}
                position={[0.88, 0.4, -0.3]}
                rotation={[Math.PI / 4, Math.PI / 1.5, 0]}
                url="https://www.yourlink.com"
                title="Triber Gallery"
                description="Showcasing our newest exhibition: Tribe Futurism."
            /> */}
            <Marker
                parentRef={markerGroupRef}
                position={[0.78, .2, -.6]}
                rotation={[Math.PI / 16, Math.PI / 1.5, 0]}
                url="https://triberworld.triber.space"
                title="Triber Gallery (Preview)"
                description="Showcasing our newest exhibition: Tribe Futurism."
            />
        </primitive>
    );
};

export default Globe;
