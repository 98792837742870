import * as React from "react";
import { getOverrideProps } from "../../ui-components/utils";
import { Flex, Text } from "@aws-amplify/ui-react";
import WorldCardCollection from "./WorldsCollection";
import ArtSpaces from "./Spaces/ArtSpaces";
import { useState, useEffect } from "react";
import { useContext } from "react";
import MusicSpaces from "./Spaces/MusicSpaces";
import MovieSpaces from "./Spaces/MovieSpaces";
import { GlobalContext } from "../../contexts/GlobalStore";
import StoreSpaces from "./Spaces/StoreSpaces";
export default function WorldsContent(props) {
  const { overrides, ...rest } = props;
  // State to track window width
  const {isMobileView} = useContext(GlobalContext)

  return (
    <Flex
      gap="72px"
      direction="column"
      width="unset"
      height={"unset"}
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "WorldsContent")}
      {...rest}
    >
      <Flex
        gap="24px"
        direction="column"
        width="100%"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Header and Sort")}
      >
        <Flex
          direction="row"
          width="100%"
          height="unset"
          justifyContent="space-between"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Header and Sort")}
        >
          <Text
            fontFamily="Work Sans"
            fontSize="24px"
            fontWeight="600"
            color="rgba(255,255,255,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="-0.2px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Gallery Spaces"
            {...getOverrideProps(overrides, "Video Spaces")}
          ></Text>
        </Flex>
          <ArtSpaces/>
      </Flex>
      <Flex
        gap="16px"
        direction="column"
        width="100%"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Header and Sort")}
      >
        <Flex
          gap="24px"
          direction="row"
          width="100%"
          height="unset"
          justifyContent="space-between"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Header and Sort")}
        >
          <Text
            fontFamily="Work Sans"
            fontSize="24px"
            fontWeight="600"
            color="rgba(255,255,255,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="-0.2px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Music Spaces"
            {...getOverrideProps(overrides, "Music Spaces")}
          ></Text>
        </Flex>
        <Flex
          gap="24px"
          direction="row"
          width="100%"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          wrap="wrap"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 42")}
        >
          <MusicSpaces/>
        </Flex>
      </Flex>
      <Flex
        gap="16px"
        direction="column"
        width="100%"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Header and Sort")}
      >
        <Flex
          gap="24px"
          direction="row"
          width="100%"
          height="unset"
          justifyContent="space-between"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Header and Sort")}
        >
          <Text
            fontFamily="Work Sans"
            fontSize="24px"
            fontWeight="600"
            color="rgba(255,255,255,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="-0.2px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Video Spaces"
            {...getOverrideProps(overrides, "Art Spaces")}
          ></Text>
        </Flex>
        <Flex
          gap="24px"
          direction="row"
          width="100%"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          wrap="wrap"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 42")}
        >
          <MovieSpaces/>
        </Flex>
      </Flex>
      <Flex
        gap="16px"
        direction="column"
        width="100%"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Header and Sort")}
      >
        <Flex
          gap="24px"
          direction="row"
          width="100%"
          height="unset"
          justifyContent="space-between"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Header and Sort")}
        >
          <Text
            fontFamily="Work Sans"
            fontSize="24px"
            fontWeight="600"
            color="rgba(255,255,255,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="-0.2px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Store Spaces"
            {...getOverrideProps(overrides, "Art Spaces")}
          ></Text>
        </Flex>
        <Flex
          gap="24px"
          direction="row"
          width="100%"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          wrap="wrap"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 42")}
        >
          <StoreSpaces/>
        </Flex>
      </Flex>
      <Flex
          gap="24px"
          direction="row"
          width="100%"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="48px 16px"
          border="1px solid rgba(255,255,255, 0.4)"
          borderRadius="12px"
          {...getOverrideProps(overrides, "Header and Sort")}
        >
          <Text
            fontFamily="Work Sans"
            fontSize="24px"
            fontWeight="600"
            color="rgba(255,255,255,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="-0.2px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="More coming soon"
            {...getOverrideProps(overrides, "Art Spaces")}
          ></Text>
        </Flex>
        <Flex
          gap="24px"
          direction="row"
          width="100%"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          wrap="wrap"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 42")}
        ></Flex>
    </Flex>
  );
}
