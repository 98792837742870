import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';

const WireframeSphere = ({ color = 'white' }) => {
  const meshRef = useRef();
  const innerSphereRef = useRef();

  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.rotation.y += 0.001; // Rotate the wireframe sphere slowly
    }
    if (innerSphereRef.current) {
      innerSphereRef.current.rotation.y += 0.001; // Rotate the inner sphere at the same speed
    }
  });

  return (
    <>
      <mesh ref={meshRef}>
        <sphereGeometry args={[2.5, 32, 32]} />
        <meshStandardMaterial 
          wireframe 
          color={color} 
          emissive={color} 
          emissiveIntensity={8} // Increased emissive intensity 
        />
      </mesh>
      <mesh ref={innerSphereRef}>
        <sphereGeometry args={[2.45, 32, 32]} /> {/* Slightly smaller inner sphere */}
        <meshStandardMaterial 
          color="black" 
        />
      </mesh>
    </>
  );
};

export default WireframeSphere;
