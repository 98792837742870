import React, { useState } from "react";
import { Flex, Text } from "@aws-amplify/ui-react";
import { NavLink, useLocation } from 'react-router-dom';
import sideBar from "../../assets/Sidebar.svg";
import SideButton from "./SideButton";
import BottomButton from "./BottomButton";
import { getOverrideProps } from "../../ui-components/utils";

export default function SidebarAuthenticated(props) {
    const { overrides, ...rest } = props;
    const location = useLocation();
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [showBottomTooltip, setShowBottomTooltip] = useState(false);

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    const handleBottomMouseEnter = () => {
        setShowBottomTooltip(true);
    };

    const handleBottomMouseLeave = () => {
        setShowBottomTooltip(false);
    };

    const getActiveTab = () => {
        if (location.pathname === "/") return "Spaces";
        if (location.pathname === "/Events") return "Events";
        if (location.pathname.startsWith("/Worlds")) return "Worlds";
        if (location.pathname.startsWith("/Market")) return "Market";
        if (location.pathname.startsWith("/Profile") || location.pathname.startsWith("/Avatar")) return "Profile";
        return null;
    };

    const activeTab = getActiveTab();

    const styles = {
        icon: {
            display: 'flex',
            width: '24px',
            height: '18px',
            cursor: 'pointer',
            position: 'relative',
        },
        iconContainer: {
            display: 'flex',
            width: '100%',
            padding: '16px 16px',
            justifyContent: isCollapsed ? 'center' : 'space-between',
            alignItems: 'center',
            position: 'relative',
        },
        sidebar: {
            width: isCollapsed ? '68px' : '298px',
            transition: 'width 0.3s ease',
            overflow: 'visible',
        },
        text: {
            display: isCollapsed ? 'none' : 'block',
            transition: 'display 0.3s ease',
        },
        contentWrapper: {
            padding: isCollapsed ? '8px 8px' : '8px 16px',
        },
        sideButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: isCollapsed ? 'center' : 'flex-start',
        },
        iconOnly: {
            justifyContent: 'center',
            width: '100%',
        },
        tooltip: {
            position: 'absolute',
            left: '100%',
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: '#1f1f1f',
            color: '#ffffff',
            border: '1px solid rgba(255, 255, 255, 0.3)',
            padding: '5px 10px',
            borderRadius: '4px',
            fontSize: '12px',
            whiteSpace: 'nowrap',
            visibility: showTooltip ? 'visible' : 'hidden',
            opacity: showTooltip ? 1 : 0,
            zIndex: 9999,
            transition: 'opacity 0.2s ease, visibility 0.2s ease',
        },
        bottomTooltip: {
            position: 'absolute',
            top: isCollapsed ? '50%' : '-30%',
            left: isCollapsed ? '100%' : '50%',
            transform: isCollapsed ? 'translateY(-50%)' : 'translateX(-50%)',
            backgroundColor: '#1f1f1f',
            color: '#ffffff',
            border: '1px solid rgba(255, 255, 255, 0.3)',
            padding: '5px 10px',
            borderRadius: '4px',
            fontSize: '12px',
            whiteSpace: 'nowrap',
            visibility: showBottomTooltip ? 'visible' : 'hidden',
            opacity: showBottomTooltip ? 1 : 0,
            zIndex: 9999,
            transition: 'opacity 0.2s ease, visibility 0.2s ease',
        },
    };

    return (
        <Flex
            gap="0px"
            direction="column"
            height="876px"
            justifyContent="flex-start"
            alignItems="flex-start"
            overflow="visible"
            position="relative"
            border="1px SOLID rgba(59,58,58,1)"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(31,31,31,1)"
            style={styles.sidebar}
            {...getOverrideProps(overrides, "SidebarAuthenticated")}
            {...rest}
        >
            <div style={styles.iconContainer}>
                <Text
                    fontFamily="Work Sans"
                    fontSize="18px"
                    fontWeight="500"
                    color="rgba(255,255,255,1)"
                    lineHeight="16px"
                    textAlign="left"
                    display={styles.text.display}
                    justifyContent="unset"
                    letterSpacing="-0.11px"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children="Navigate"
                    {...getOverrideProps(overrides, "Text40072135")}
                />
                <div
                    style={styles.icon}
                    onClick={toggleSidebar}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <img
                        src={sideBar}
                        alt="Sidebar Icon"
                        style={{ width: '100%', height: '100%' }}
                    />
                    <div style={styles.tooltip}>
                        {isCollapsed ? 'Expand Sidebar' : 'Collapse Sidebar'}
                    </div>
                </div>
            </div>
            <Flex
                gap="64px"
                direction="column"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                grow="1"
                shrink="1"
                basis="0"
                alignSelf="stretch"
                position="relative"
                style={styles.contentWrapper}
                {...getOverrideProps(overrides, "ContentWrapper")}
            >
                <Flex
                    gap="24px"
                    direction="column"
                    height="unset"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    shrink="0"
                    alignSelf="stretch"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    {...getOverrideProps(overrides, "Pages")}
                >
                    <NavLink to="/" style={{ width: '100%' }}>
                        <SideButton
                            label={isCollapsed ? "" : "Spaces"}
                            iconType="Space"
                            activeIconType="Space"
                            active={activeTab === 'Spaces'}
                            width="100%"
                            style={isCollapsed ? styles.iconOnly : styles.sideButton}
                        />
                    </NavLink>
                    <NavLink to="/Events" style={{ width: '100%' }}>
                        <SideButton
                            label={isCollapsed ? "" : "Events"}
                            iconType="group"
                            activeIconType="group"
                            active={activeTab === 'Events'}
                            width="100%"
                            style={isCollapsed ? styles.iconOnly : styles.sideButton}
                        />
                    </NavLink>
                    <NavLink to="/Worlds" style={{ width: '100%' }}>
                        <SideButton
                            label={isCollapsed ? "" : "Worlds"}
                            iconType="Globe"
                            activeIconType="GlobeFilled"
                            active={activeTab === 'Worlds'}
                            width="100%"
                            style={isCollapsed ? styles.iconOnly : styles.sideButton}
                        />
                    </NavLink>
                    <NavLink to="/Market" style={{ width: '100%' }}>
                        <SideButton
                            label={isCollapsed ? "" : "Market"}
                            iconType="MarketNoFill"
                            activeIconType="MarketFill"
                            active={activeTab === 'Market'}
                            width="100%"
                            style={isCollapsed ? styles.iconOnly : styles.sideButton}
                        />
                    </NavLink>
                    {/* <NavLink to="/Profile" style={{ width: '100%' }}>
                        <SideButton
                            label={isCollapsed ? "" : "Profile"}
                            iconType="PersonNoFill"
                            activeIconType="PersonFill"
                            active={activeTab === 'Profile'}
                            width="100%"
                            style={isCollapsed ? styles.iconOnly : styles.sideButton}
                        />
                    </NavLink> */}
                </Flex>
            </Flex>
            <Flex
                gap="10px"
                direction="column"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                border="1px SOLID rgba(59,58,58,1)"
                padding={isCollapsed ? "12px 8px" : "12px"}
                onMouseEnter={handleBottomMouseEnter}
                onMouseLeave={handleBottomMouseLeave}
                {...getOverrideProps(overrides, "BottomSection")}
            >
                <BottomButton isCollapsed={isCollapsed} />
                <div style={styles.bottomTooltip}>Coming Soon</div>
            </Flex>
        </Flex>
    );
}
