import React, { useEffect, useState } from "react";
import { getOverrideProps } from "../../ui-components/utils";
import { Flex, Icon, Text } from "@aws-amplify/ui-react";
import MenuItems from "./MenuItems";
import LogIn from "../Login/Login";
import MobileLogo from "../../assets/MobileLogo.svg"

export default function Navbar(props) {
    const { isAuth, overrides, ...rest } = props;

    const styles = {
        logo: {
            width: '38px', // Adjust to fit your design
            height: '30px',   // Adjust to fit your design
            // Add other styles as needed, e.g., margin, padding
        },
        navbar: {
            borderBottom: '1px solid rgba(59,58,58,1)',
            padding: '16px 24px',
            backgroundColor: 'rgba(31,31,31,1)',
        },
    };

    return (
        <Flex
            gap="10px"
            direction="column"
            width="100%"
            height="64px"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
            position="relative"
            padding="16px 24px 16px 24px"
            backgroundColor="rgba(31,31,31,1)"
            style={styles.navbar}
            {...getOverrideProps(overrides, "Navbar")}
            {...rest}
        >
            <Flex
                gap="unset"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="space-between"
                alignItems="center"
                shrink="0"
                alignSelf="stretch"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Content")}
            >
                <Flex
                    padding="0px 0px 0px 0px"
                    width="unset"
                    height="30px"
                    display="flex"
                    gap="unset"
                    alignItems="unset"
                    justifyContent="center"
                    shrink="0"
                    position="relative"
                    {...getOverrideProps(overrides, "Icon")}
                >
                    <img
                        src={MobileLogo}
                        alt="TriberSpace Logo"
                        style={styles.logo}
                    />
                </Flex>
                {isAuth && <MenuItems />}
                {!isAuth && <LogIn />}
            </Flex>
        </Flex>
    );
}