import React, { useState, useEffect, useContext } from "react";
import { getOverrideProps } from "../ui-components/utils";
import { Flex } from "@aws-amplify/ui-react";
import { GlobalContext } from "../contexts/GlobalStore";

export default function ContentWrapper(props) {
  const { overrides, ...rest } = props;

  const { isMobileView } = useContext(GlobalContext);

  const [padding, setPadding] = useState("14px 36px");

  return (
    <Flex
      gap="48px"
      direction="column"
      width="100%"
      height={isMobileView ? "calc(100svh - 124px)" : "calc(100vh - 64px)"}
      justifyContent="flex-start"
      alignItems="flex-start"
      overflow="auto"
      position="relative"
      padding={isMobileView ? "16px 24px" : "14px 36px"}
      backgroundColor="rgba(31,31,31,1)"
      {...getOverrideProps(overrides, "ContentWrapper")}
      {...rest}
    ></Flex>
  );
}
