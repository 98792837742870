import React from 'react';
import './Spaces.css';

const ArtSpaces = () => {
  const cardData = [
    { title: 'Triber Gallery', worldName: "Triber World", image: '/assets/TriberGallery.png', url: 'https://triberworld.triber.space' },
    { title: 'Beloved Gallery', worldName: "Beloved. World", image: 'assets/BelovedGallery.png', url: '#' },
    { title: 'V2 Gallery', worldName: "V2 World", image: 'assets/V2Gallery.png', url: '#' },
    { title: 'Ajaar Gallery', worldName: "Ajaar World", image: 'assets/AjaarGallery.png', url: '#' },
  ];

  return (
    <div className="card-collection">
      {cardData.map((card, index) => (
        <a href={card.url} target="_blank" className="card-wrapper" key={index}>
          <div className="card">
            <div
              className="card-image"
              style={{
                backgroundImage: card.image ? `url(${card.image})` : undefined,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            ></div>
            <div className="content-box">
              <div className="card-title">{card.title}</div>
              <div className="world-name">{card.worldName}</div>
            </div>
          </div>
        </a>
      ))}
    </div>
  );
};

export default ArtSpaces;
