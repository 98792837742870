import * as React from "react";
import { useState, useEffect } from "react";
import { getOverrideProps } from "../ui-components/utils";
import SidebarContainer from "./Sidebar/SidebarContainer";
import { Flex } from "@aws-amplify/ui-react";
import HomeContainer from "./Home/HomeContainer";
import MarketContainer from "./Market/MarketContainer";
import ProfileContainer from "./Profile/ProfileContainer";
import AvatarContainer from "./Avatar/AvatarContainer";
import CalendarContainer from "./Calendar/CalendarContainer";
import TribeContainer from "./Tribes/TribeContainer";
import { Routes, Route, Outlet } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import MobileNavbar from "./Navbar/MobileNavbar";
import MobileSidebar from "./Sidebar/MobileSidebar";
import WorldsView from "./Worlds/WorldsView";
import TempMarketContainer from "./Market/TempMarketContainer";
import { GlobalContext } from "../contexts/GlobalStore";
import ContentWrapper from "./ContentWrapper";
import EventsContent from "./Events/EventsContent";

export default function Dashboard(props) {
  const { isAuth, overrides, ...rest } = props;

  // Initialize state from session storage or default to "Home"
  const [activeTab, setActiveTab] = useState(
    sessionStorage.getItem("activeTab") || "Home"
  );

  const {isMobileView} = React.useContext(GlobalContext)

  return (
    <Flex
      direction="column"
      width="100%"
      height="100vh" // Full viewport height
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="0px"
      gap="0px"
      {...getOverrideProps(overrides, "Dashboard")}
      {...rest}
    >
      {isMobileView ? (
        <MobileNavbar key={`${isMobileView}-${isAuth}`} width="100%" height="64px" isAuth={isAuth} /> // Fixed height for mobile navbar
      ) : (
        <Navbar width="100%" height="64px" isAuth={isAuth} /> // Fixed height for desktop navbar
      )}
      <Flex
        direction={isMobileView ? "column" : "row"} // Adjust direction based on view
        width="100%"
        height={isMobileView ? 'calc(100svh - 124px)' : 'calc(100vh - 64px)'} // Subtract Navbar height from the viewport
        justifyContent="flex-start"
        alignItems={isMobileView ? "stretch" : "flex-start"} // Stretch on mobile for full width
        position="relative"
        padding="0px"
        gap="0px"
        {...getOverrideProps(overrides, "Main Content")}
      >
        {isMobileView ? (
          <Flex direction="column" width="100%" height="100%">
            <Flex flex="1" overflow="auto">
              {/* Content area */}
              <Routes>
                <Route path="/*" element={<HomeContainer />} /> {/* Changed to HomeContainer */}
                <Route path="/Events" element={<EventsContent />} /> {/* Added Events route */}
                <Route path="Worlds" element={<WorldsView />} />
                <Route path="Market/*" element={<TempMarketContainer />} />
                <Route path="profile" element={<ProfileContainer isAuth={isAuth} />} />
                <Route path="avatar" element={<AvatarContainer />} />
              </Routes>
              <Outlet />
            </Flex>
            <MobileSidebar
              width="100%"
              height="60px"
              position="sticky"
              bottom="0"
              flexShrink="0"
            />
          </Flex>
        ) : (
          <SidebarContainer
            activeTab={activeTab}
            onTabChange={setActiveTab}
            height="100%"
          />
        )}
        {!isMobileView && (
          <Flex
            direction="column"
            width="100%"
            height="100%"
            overflow="auto" // Enable scrolling if content exceeds the height
          >
            <Routes>
              <Route path="/*" element={<HomeContainer />} /> {/* Changed to HomeContainer */}
              <Route path="/Events" element={<EventsContent />} /> {/* Added Events route */}
              <Route path="Worlds" element={<WorldsView />} />
              <Route path="Market/*" element={<TempMarketContainer />} />
              <Route path="profile" element={<ProfileContainer isAuth={isAuth} />} />
              <Route path="avatar" element={<AvatarContainer />} />
            </Routes>
            <Outlet />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
