import * as React from "react";
import { useState } from "react";
import { Flex } from "@aws-amplify/ui-react";
import { NavLink, useLocation } from 'react-router-dom';
import SideButton from "./SideButton";

export default function MobileSidebar(props) {
    const location = useLocation();
    const getActiveTab = () => {
        if (location.pathname === "/") return "Spaces";
        if (location.pathname === "/Events") return "Events";
        if (location.pathname.startsWith("/Worlds")) return "Worlds";
        if (location.pathname.startsWith("/Market")) return "Market";
        if (location.pathname.startsWith("/Profile") || location.pathname.startsWith("/Avatar")) return "Profile";
        return null;
    };

    const activeTab = getActiveTab();

    const styles = {
        bottomBar: {
            position: 'fixed',
            bottom: 0,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',
            backgroundColor: 'rgba(31,31,31,1)',
            padding: '10px 0',
            borderTop: '1px solid rgba(59,58,58,1)',
            zIndex: 1000, // Ensure it's above other elements
        },
        iconOnly: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
        },
    };

    return (
        <Flex
            style={styles.bottomBar}
        >
            <NavLink to="/">
                <SideButton
                    label={ ""}
                    iconType="Space"
                    activeIconType="Space"
                    active={activeTab === 'Spaces'}
                    style={styles.iconOnly}
                />
            </NavLink>
            <NavLink to="/Events">
                <SideButton
                    label=""
                    iconType="group"
                    activeIconType="group"
                    active={activeTab === 'Events'}
                    style={styles.iconOnly}
                />
            </NavLink>
            <NavLink to="/Worlds">
                <SideButton
                    label=""
                    iconType="Globe"
                    activeIconType="GlobeFilled"
                    active={activeTab === 'Worlds'}
                    style={styles.iconOnly}
                />
            </NavLink>
            <NavLink to="/Market">
                <SideButton
                    label=""
                    iconType="MarketNoFill"
                    activeIconType="MarketFill"
                    active={activeTab === 'Market'}
                    style={styles.iconOnly}
                />
            </NavLink>
            {/* <NavLink to="/Profile">
                <SideButton
                    label=""
                    iconType="PersonNoFill"
                    activeIconType="PersonFill"
                    active={activeTab === 'Profile'}
                    style={styles.iconOnly}
                />
            </NavLink> */}
        </Flex>
    );
}
