import React from "react";
import { useNavigate } from "react-router-dom";
import "../eventCard.css";
import "./style.css";

const HappeningNow = ({ events, onViewUpcoming }) => {
  const navigate = useNavigate();
  const emptyStateText =
    "There are currently no events taking place. Find an upcoming event or explore a Space in the meantime.";

  const handleExploreSpaces = () => {
    navigate("/Spaces");
  };

  if (events.length === 0) {
    return (
      <main className="section-top-wrapper">
        <div className="section-inner-wrapper">
          <div id="text" style={{ textAlign: "center", color: "white" }}>
            {emptyStateText}
          </div>
          <div className="button-section">
            <button
              style={{
                backgroundColor: "transparent",
                color: "white",
                border: "white solid 3px",
              }}
              onClick={onViewUpcoming}
            >
              View Upcoming
            </button>
            <button onClick={handleExploreSpaces}>Explore Spaces</button>
          </div>
        </div>
      </main>
    );
  }

  return (
    <div className="event-card-collection">
      {events.map((card, index) => (
        <a href={card.url} target="_blank" rel="noopener noreferrer" className="event-card-wrapper" key={index}>
          <div className="event-card">
            <div className="event-card-image-container">
              <div
                className="event-card-image"
                style={{
                  backgroundImage: card.image ? `url(${card.image})` : undefined,
                }}
              ></div>
            </div>
            <div className="event-card-content-box">
              <div className="event-card-title">{card.title}</div>
              <div className="event-card-details">
                <span className="event-card-space-name">{card.spaceName}</span>
                <span className="event-card-separator">●</span>
                <span className="event-card-world-name">{card.worldName}</span>
              </div>
            </div>
          </div>
        </a>
      ))}
    </div>
  );
};

export default HappeningNow;
