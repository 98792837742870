import React from "react";
import { Flex, Text } from "@aws-amplify/ui-react";
import MyIcon from "../../ui-components/MyIcon"; // Assuming this is your icon component
import { getOverrideProps } from "../../ui-components/utils";

export default function BottomButton({ isCollapsed, ...props }) {
  return (
    <Flex
      gap="24px"
      direction="row"
      width="100%"
      height="unset"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      borderRadius="12px"
      padding={isCollapsed ? "12px 12px" : "12px 12px 12px 12px"}
      backgroundColor={isCollapsed ? "rgba(222,232,237,0)" : "rgba(222,232,237,1)"}
      {...getOverrideProps(props.overrides, "BottomButton")}
      {...props}
    >
      {isCollapsed ? (
        <MyIcon style={{ fill: 'black' }} type="file" /> // Render the file icon when collapsed
      ) : (
        <Text
          fontFamily="Work Sans"
          fontSize="24px"
          fontWeight="600"
          color="rgba(31,31,31,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="-0.11px"
          whiteSpace="nowrap"  // Prevents wrapping
          overflow="hidden"    // Hides any overflow
          textOverflow="ellipsis" // Adds ellipsis if the text overflows
          children="Apply Now"
          {...getOverrideProps(props.overrides, "Text")}
        />
      )}
    </Flex>
  );
}
