import React, { useContext } from 'react';
import { GlobalContext } from '../../contexts/GlobalStore';
import tribeCoin from "../../assets/TribeCoin.svg"

const TribePoints = () => {
    const { currentUserProfile } = useContext(GlobalContext);
    const points = currentUserProfile?.totalPoints || 0;

    const styles = {
        container: {
            display: 'flex',
            alignItems: 'center',
            color: 'white',
            fontFamily: 'Work Sans',
        },
        svg: {
            width: '36px', // Adjust as needed
            height: '36px', // Adjust as needed
            marginRight: '8px',
        },
        text: {
            fontSize: '24px',
            fontWeight: '600',
        },
    };

    return (
        <div style={styles.container}>
           <img
            src={tribeCoin}
            alt="Tribe Coin"
            style={styles.svg}
          />
            <div style={styles.text}>{points} TP</div>
        </div>
    );
};

export default TribePoints;
