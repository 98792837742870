import React, { createContext, useState, useEffect } from 'react';
import { listEvents, getProfile, listProfiles, listWorlds } from '../graphql/queries';
import { createPoints, updateProfile, createTransaction, createInventoryItem } from '../graphql/mutations';
import { generateClient } from 'aws-amplify/api';
import { getCurrentUser } from "aws-amplify/auth";
import { Hub } from 'aws-amplify/utils';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [eventsData, setEventsData] = useState([]);
  const [worldsData, setWorldsData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [loading, setLoading] = useState(true);
  const pageSize = 6;
  const [userProfile, setUserProfile] = useState(null);

  const [eventDetails, setEventDetails] = useState(null); // Define eventDetails and setEventDetails
  const [eventLoading, setEventLoading] = useState(false); // Define eventLoading and setEventLoading

  const client = generateClient();
  const publicClient = generateClient({ authMode: 'apiKey' });

  const [currentUserProfile, setCurrentUserProfile] = useState(null);

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetchUserProfile().then(profile => setCurrentUserProfile(profile));

    const listener = async (data) => {
      const { payload } = data;
      if (payload.event === 'signedIn' || payload.event === 'signedOut') {
        const profile = await fetchUserProfile();
        setCurrentUserProfile(profile);
      }
    };

    const hubListenerCancel = Hub.listen('auth', listener);

    return () => hubListenerCancel();
  }, []);

  async function fetchUserProfile() {
    try {
      const currentUser = await getCurrentUser();
      const userId = currentUser.username;

      const response = await client.graphql({
        query: listProfiles,
      });

      const profiles = response.data.listProfiles.items;
      const userProfile = profiles.find(profile => profile.owner === userId);

      return userProfile || null;
    } catch (error) {
      console.error('Error fetching user profile:', error);
      return null;
    }
  }

  async function checkUserProfile() {
    try {
      const currentUser = await getCurrentUser();
      const userId = currentUser.username;

      const response = await client.graphql({
        query: listProfiles,
      });

      const profiles = response.data.listProfiles.items;
      const userProfile = profiles.find(profile => profile.owner === userId);

      setUserProfile(userProfile || null);

      return userProfile ? true : false;
    } catch (error) {
      console.error('Error checking user profiles:', error);
      return false;
    }
  }

  useEffect(() => {
    const loadEvents = async () => {
      setLoading(true);
      try {
        const result = await publicClient.graphql({
          query: listEvents.replaceAll("__typename", ""),
          variables: { limit: pageSize },
        });
        if (result.data && result.data.listEvents) {
          setEventsData(result.data.listEvents.items);
          setHasMorePages(!!result.data.listEvents.nextToken);
        } else {
          console.error("No data received from the query");
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
      setLoading(false);
    };

    loadEvents();
  }, [pageIndex]);

  useEffect(() => {
    const loadWorlds = async () => {
      setLoading(true);
      try {
        const result = await publicClient.graphql({
          query: listWorlds.replaceAll("__typename", ""),
          variables: { limit: pageSize },
        });
        if (result.data && result.data.listWorlds) {
          setWorldsData(result.data.listWorlds.items);
          setHasMorePages(!!result.data.listWorlds.nextToken);
        } else {
          console.error("No data received from the query");
        }
      } catch (error) {
        console.error("Error fetching Worlds:", error);
      }
      setLoading(false);
    };

    loadWorlds();
  }, [pageIndex]);

  const deductPoints = async (userId, points, reason = 'Product Purchased') => {
    try {
      const createPointsInput = {
        userId,
        amount: -points,
        reason,
      };
      await client.graphql({
        query: createPoints,
        variables: { input: createPointsInput },
      });

      const userProfile = await client.graphql({
        query: getProfile,
        variables: { id: userId },
      });

      if (userProfile.data.getProfile) {
        const updatedTotalPoints = userProfile.data.getProfile.totalPoints - points;
        await client.graphql({
          query: updateProfile,
          variables: { input: { id: userId, totalPoints: updatedTotalPoints } },
        });
      }
      console.log(`Points deducted from user ${userId}`);
    } catch (error) {
      console.error(`Error deducting points from user ${userId}:`, error);
    }
  };

  const createInventoryItemMutation = `
    mutation CreateInventoryItem($input: CreateInventoryItemInput!) {
        createInventoryItem(input: $input) {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
        }
    }
  `;

  async function initiateTribePointsCheckout(cart, userProfileId) {
    try {
      const userProfile = await client.graphql({
        query: getProfile,
        variables: { id: userProfileId },
      });

      const totalPointsNeeded = cart.reduce((acc, product) => acc + product.price * 10, 0);

      if (userProfile.data.getProfile.totalPoints >= totalPointsNeeded) {
        for (const product of cart) {
          await publicClient.graphql({
            query: createTransaction,
            variables: {
              input: {
                productID: product.id,
                userID: userProfileId,
                paymentMethod: 'TRIBE_POINTS',
              }
            }
          });

          await publicClient.graphql({
            query: createInventoryItemMutation,
            variables: {
              input: {
                ownerId: userProfileId,
                productId: product.id,
                acquiredDate: new Date().toISOString(),
                status: 'ACQUIRED',
              }
            }
          });
        }

        await deductPoints(userProfileId, totalPointsNeeded);
        return { success: true, message: "Transaction successful" };
      } else {
        console.log('Not enough Tribe Points');
        return { success: false, message: 'Not enough Tribe Points' };
      }
    } catch (error) {
      console.error('Error initiating TP checkout:', error);
      return false;
    }
  }

  const [cart, setCart] = useState([]);
  const [purchasedItems, setPurchasedItems] = useState([]);

  const addToCart = (productToAdd) => {
    const existingProduct = cart.find(product => product.id === productToAdd.id);

    if (existingProduct) {
      const updatedCart = cart.map(product =>
        product.id === productToAdd.id
          ? { ...product, quantity: (product.quantity || 1) + 1 }
          : product
      );
      setCart(updatedCart);
    } else {
      setCart([...cart, { ...productToAdd, quantity: 1 }]);
    }
  };

  const removeFromCart = (productId) => {
    setCart(cart.filter(item => item.id !== productId));
  };

  const emptyCart = () => {
    setCart([]);
  };

  return (
    <GlobalContext.Provider value={{
      eventsData, setEventsData, worldsData, setWorldsData, pageIndex, setPageIndex, currentUserProfile, setCurrentUserProfile, isMobileView,
      hasMorePages, loading, eventDetails, setEventDetails, eventLoading, checkUserProfile, userProfile, setUserProfile, initiateTribePointsCheckout, addToCart, removeFromCart, cart, setCart, emptyCart, purchasedItems, setPurchasedItems
    }}>
      {children}
    </GlobalContext.Provider>
  );
};
