import React from 'react';
import { CreateProfileSettings } from '../../ui-components';
import { Flex } from '@aws-amplify/ui-react';
import { Authenticator } from "@aws-amplify/ui-react";
import { useNavigate } from 'react-router-dom';
import triberspaceLogo from "../../assets/TriberspaceLogo.svg"

const ProfileSetup = () => {
  const navigate = useNavigate();

  // Function to handle successful profile creation
  const handleSuccess = () => {
    navigate('/');
  };

  const styles = {
    logo: {
      width: '216px', // Adjust to fit your design
      height: '30px',   // Adjust to fit your design
      // Add other styles as needed, e.g., margin, padding
    },
  };

  return (
    <Authenticator>
      <Flex backgroundColor="#1f1f1f" direction="column" alignItems="center" justifyContent="center" style={{ height: '100vh', overflowY:'auto' }}>
      <img
            src={triberspaceLogo}
            alt="TriberSpace Logo"
            style={styles.logo}
          />
        <Flex 
          direction="column" 
          alignItems="center" 
          justifyContent="center" 
          padding="24px 12px" 
          maxWidth="500px"
          gap="12px"
          style={{ width: '80%', backgroundColor: '#F2F2F2', borderRadius: '12px' }}
        >
          <h1 style={{ fontSize: '32px', fontWeight: '600', fontFamily: 'Work Sans' }}>Profile Setup</h1>
          <CreateProfileSettings onSuccess={handleSuccess} style={{ width: '100%' }}/>
        </Flex>
      </Flex>
    </Authenticator>
  );
};

export default ProfileSetup;
