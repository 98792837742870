import React from 'react';
import { useNavigate} from 'react-router-dom';
import ContentWrapper from '../ContentWrapper';

const ProfileEmpty = () => {
    const navigate = useNavigate();

    const styles = {
        emptyContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontSize: '20px',
            fontFamily: 'Work Sans',
            fontWeight: '500',
            border: '3px solid rgba(255, 255, 255, 0.4)',
            borderRadius: '16px',
            textAlign: 'center',
            padding: '24px',
            boxSizing: 'border-box'
        },
        button: {
            marginTop: '24px',
            padding: '8px 24px',
            backgroundColor: '#d3d3d3', // Light gray background
            color: 'black',
            fontFamily: 'Work Sans',
            fontSize: '20px',
            fontWeight: '500',
            borderRadius: '24px', // Fully rounded
            border: '1px solid #b0b0b0', // Slightly darker border
            cursor: 'pointer',
            textDecoration: 'none'
        }
    }

    const handleSignInClick = () => {
        navigate('/authenticate')
    }

    return (
        <ContentWrapper>
            <div style={styles.emptyContainer}>
                <div>Sign in to customize your profile.</div>
                <button 
                    style={styles.button} 
                    onClick={handleSignInClick}
                >
                    Sign In
                </button>
            </div>
        </ContentWrapper>
    );
}

export default ProfileEmpty;
