import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const PasswordPage = ({ onPasswordCorrect }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password === 'tribesh!t') {
      onPasswordCorrect();
    } else {
      setError('Incorrect password');
    }
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        padding: '24px',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'fixed',
        backgroundColor: '#1f1f1f',
        color: 'white',
        zIndex: '1000000'
      }}
    >
      <img
        src={`${process.env.PUBLIC_URL}/assets/TriberspaceLogo.svg`}
        alt="Loading GIF"
        width="250px"
      />
      <div
        style={{
          padding: '12px',
          color: 'white',
          maxWidth: '875px',
          textAlign: 'center',
          fontSize: '16px',
          fontFamily: 'Work Sans',
          padding: '16px'
        }}>
        An immersive social media platform that enables creators to place their content in custom 3D Spaces. Within Triber Media Spaces, users earn TRIBE points by connecting deeply with Content and Community. <br></br> <br></br>Triber.space transforms passive audiences to connected tribes who engage deeply.
      </div>
      <form onSubmit={handleSubmit} style={{ marginTop: '20px', display: 'flex', alignItems: 'center' }}>
        <input
          type="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder="Enter Password"
          style={{
            width: '250px',
            padding: '12px',
            border: '1px solid white',
            borderRadius: '16px 0 0 16px', // Rounded corners on the left side only
            backgroundColor: 'transparent',
            color: 'white',
            fontSize: '16px',
            textAlign: 'center',
            outline: 'none',
            borderRight: 'none' // Remove the right border to connect with the button
          }}
        />
        <button
          type="submit"
          style={{
            padding: '12px 16px',
            backgroundColor: '#f8f8f8',
            color: 'black',
            border: '1px solid lightgray',
            borderRadius: '0 16px 16px 0', // Rounded corners on the right side only
            fontSize: '16px',
            fontFamily: 'Work Sans',
            cursor: 'pointer',
            outline: 'none',
            borderLeft: 'none' // Remove the left border to connect with the input field
          }}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </form>
      {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>}
    </div>
  );
};

export default PasswordPage;
