import React, { useState } from "react";
import { getOverrideProps } from "../../ui-components/utils";
import { Flex, Icon, Text } from "@aws-amplify/ui-react";
import MenuItems from "./MenuItems";
import LogIn from "../Login/Login";
import triberspaceLogo from "../../assets/TriberSpaceLogoUpd.svg";

export default function Navbar(props) {
  const { isAuth, overrides, ...rest } = props;
  console.log("isAuth navbar", isAuth);

  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const styles = {
    logo: {
      width: '216px',
      height: '30px',
    },
    searchBar: {
      position: 'relative',
    },
    tooltip: {
      position: 'absolute',
      bottom: '0',
      left: '50%',
      transform: 'translateX(-50%)',
      backgroundColor: '#1f1f1f',
      color: '#ffffff',
      border: '1px solid rgba(255, 255, 255, 0.3)',
      padding: '5px 10px',
      borderRadius: '4px',
      fontSize: '12px',
      whiteSpace: 'nowrap',
      visibility: showTooltip ? 'visible' : 'hidden',
      opacity: showTooltip ? 1 : 0,
      zIndex: 9999,
      transition: 'opacity 0.2s ease, visibility 0.2s ease',
    },
  };

  return (
    <Flex
      gap="10px"
      direction="column"
      width="1600px"
      height="64px"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      border="1px SOLID rgba(59,58,58,1)"
      padding="17px 35px 17px 35px"
      backgroundColor="rgba(31,31,31,1)"
      {...getOverrideProps(overrides, "Navbar")}
      {...rest}
    >
      <Flex
        gap="unset"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Content")}
      >
        <Flex
          padding="0px 0px 0px 0px"
          width="300px"
          height="30px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "Icon")}
        >
          <img
            src={triberspaceLogo}
            alt="TriberSpace Logo"
            style={styles.logo}
          />
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="36px"
          justifyContent="flex-end"
          alignItems="center"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          borderRadius="64px"
          padding="12px 26px 12px 26px"
          backgroundColor="rgba(217,217,217,1)"
          maxWidth="700px"
          style={styles.searchBar}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          {...getOverrideProps(overrides, "Search Bar")}
        >
          <Flex
            padding="0px 0px 0px 0px"
            width="16px"
            height="16px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            {...getOverrideProps(
              overrides,
              '\uD83E\uDD86 icon "magnifying glass"'
            )}
          >
            <Icon
              width="16px"
              height="16px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 16,
                height: 16.000003814697266,
              }}
              paths={[
                {
                  d: "M6.9372 0.0198206C3.11183 0.0198206 0 3.13165 0 6.95702C0 10.7824 3.11183 13.8942 6.9372 13.8942C8.10661 13.8942 9.2562 13.6167 10.2274 13.0816C10.3052 13.1752 10.3915 13.2614 10.4851 13.3392L12.4671 15.3213C12.6501 15.5272 12.8733 15.6936 13.123 15.8102C13.3726 15.9267 13.6434 15.991 13.9188 15.9991C14.1942 16.0072 14.4683 15.959 14.7244 15.8573C14.9805 15.7556 15.213 15.6027 15.4079 15.4079C15.6027 15.213 15.7556 14.9805 15.8573 14.7244C15.959 14.4683 16.0072 14.1942 15.9991 13.9188C15.991 13.6434 15.9267 13.3726 15.8101 13.123C15.6936 12.8733 15.5272 12.6502 15.3213 12.4671L13.3392 10.4851C13.2427 10.3885 13.1364 10.3021 13.0221 10.2274C13.5573 9.2562 13.8942 8.12643 13.8942 6.9372C13.8942 3.11183 10.7824 0 6.95702 0L6.9372 0.0198206ZM6.9372 2.00188C9.69225 2.00188 11.8923 4.20196 11.8923 6.95702C11.8923 8.26517 11.4166 9.47423 10.5842 10.3662C10.5644 10.386 10.5445 10.4058 10.5247 10.4256C10.4311 10.5034 10.3448 10.5897 10.267 10.6833C9.39495 11.4761 8.20571 11.932 6.91738 11.932C4.16232 11.932 1.96224 9.7319 1.96224 6.97684C1.96224 4.22178 4.16232 2.0217 6.91738 2.0217L6.9372 2.00188Z",
                  fill: "rgba(74,74,74,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              position="absolute"
              top="0%"
              bottom="0%"
              left="0%"
              right="0%"
              {...getOverrideProps(overrides, "Vector")}
            ></Icon>
          </Flex>
          {/* <div style={styles.tooltip}>Search Coming Soon</div> */}
        </Flex>
        {isAuth && <MenuItems />}
        {!isAuth && <LogIn />}
      </Flex>
    </Flex>
  );
}
