import React, { useEffect, useState, useContext } from "react";
import "./index.css";
import Dashboard from "./ui-containers/Dashboard";
import "@aws-amplify/ui-react/styles.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Hub } from "aws-amplify/utils";
import { getCurrentUser, fetchAuthSession, signOut } from "aws-amplify/auth";
import ProfileSetup from "./ui-containers/Profile/ProfileSetup";
import { GlobalContext } from "./contexts/GlobalStore";
import AuthenticatorWrapper from "./ui-containers/Authenticator/AuthenticatorWrapper";

const App = () => {
  const { checkUserProfile } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [isAuth, setAuth] = useState(false);
  const [authLoading, setAuthLoading] = useState(false);

  async function currentAuthenticatedUser() {
    try {
      const user = await getCurrentUser();
      if (user) {
        setAuth(true);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function checkUserAuthentication() {
    try {
      const user = await getCurrentUser();
      if (user) {
        console.log('User is authenticated:', user);
        // Additional logic for authenticated users
      } else {
        console.log('User is not authenticated.');
      }
    } catch (error) {
      console.error('Error fetching user session:', error);
    }
  }
  
  useEffect(() => {
    checkUserAuthentication();
  }, []);

  async function handleSignOut() {
    try {
      await signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  useEffect(() => {
    currentAuthenticatedUser();
  });

  useEffect(() => {
  const listener = async (data) => {
    const { payload } = data;
    if (payload.event === "signedIn") {
      setAuth(true);
      setAuthLoading(true);
      const profileExists = await checkUserProfile();
      setAuthLoading(false);
      if (!profileExists) {
        navigate('/profilesetup');
      } else {
        navigate('/');
        // Force layout recalculation
        setTimeout(() => window.scrollTo(0, 0), 0);
      }
    }
    if (payload.event === "signedOut") {
      console.log("user has signed out");
      setAuth(false);
    }
  };

  const hubListenerCancel = Hub.listen("auth", listener);

  return () => {
    hubListenerCancel(); // Cleanup
  };
}, [navigate, checkUserProfile]);

useEffect(() => {
  setTimeout(() => window.scrollTo(0, 0), 0);
}, []);

  return (
    <>
          <Routes>
            <Route path="/authenticate" element={<AuthenticatorWrapper authLoading={authLoading}/>} />
            <Route path="/profilesetup" element={<ProfileSetup />} />
            <Route path="/*" element={<Dashboard isAuth={isAuth} width="100%"/>} />
          </Routes>
    </>
  );
};

export default App;
