import React from 'react';
import './Spaces.css';

const MovieSpaces = () => {
  const cardData = [
    { title: 'Triber Theatre', worldName: "Triber World", image: '/assets/TriberTheatre.png', url: '#' },
    { title: 'Beloved Theatre', worldName: "Beloved. World", image: 'assets/BelovedTheatre.png', url: '#' },
    { title: 'V2 Theatre', worldName: "V2 World", image: 'assets/V2Theatre.png', url: '#' },
    { title: 'Ajaar Theatre', worldName: "Ajaar World", image: 'assets/AjaarTheatre.png', url: '#' },
  ];

  return (
    <div className="card-collection">
      {cardData.map((card, index) => (
        <a href={card.url} className="card-wrapper" key={index}>
          <div className="card">
            <div
              className="card-image"
              style={{
                backgroundImage: card.image ? `url(${card.image})` : undefined,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            ></div>
            <div className="content-box">
              <div className="card-title">{card.title}</div>
              <div className="world-name">{card.worldName}</div>
            </div>
          </div>
        </a>
      ))}
    </div>
  );
};

export default MovieSpaces;
