import React from "react";
import "../eventCard.css";

const Upcoming = ({ events }) => {
  return (
    <div className="event-card-collection">
      {events.map((card, index) => (
        <a href={card.url} target="_blank" rel="noopener noreferrer" className="event-card-wrapper" key={index}>
          <div className="event-card">
            <div className="event-card-image-container">
              <div
                className="event-card-image"
                style={{
                  backgroundImage: card.image ? `url(${card.image})` : undefined,
                }}
              ></div>
            </div>
            <div className="event-card-content-box">
              <div className="event-card-title">{card.title}</div>
              <div className="event-card-details">
                <span className="event-card-space-name">{card.spaceName}</span>
                <span className="event-card-separator">●</span>
                <span className="event-card-world-name">{card.worldName}</span>
              </div>
            </div>
          </div>
        </a>
      ))}
    </div>
  );
};

export default Upcoming;
