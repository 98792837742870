// AvatarContainer.jsx
import React, { useContext, useEffect } from 'react';
import ContentWrapper from '../ContentWrapper';
import AvatarContent from './AvatarContent';

const AvatarContainer = () => {

  return (
    <ContentWrapper width={"100%"}>
      <AvatarContent/>
    </ContentWrapper>
  );
};

export default AvatarContainer;
