import React from 'react';
import ContentWrapper from '../ContentWrapper';
import './TempMarketContainer.css'

const TempMarketContainer = () => {
    const styles = {
        tempContainer: {
            display: 'flex',
            width: '100%',
            padding: '96px 24px',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontSize: '20px',
            fontFamily: 'Work Sans',
            fontWeight: '500',
            border: '3px solid rgba(255, 255, 255, 0.4)',
            borderRadius: '16px'
        }
    }
    const cardData = [
        { title: 'Coming Soon' },
        { title: 'Coming Soon' },
        { title: 'Coming Soon' },
        { title: 'Coming Soon' },
        { title: 'Coming Soon' },
        { title: 'Coming Soon' },
        { title: 'Coming Soon' },
        { title: 'Coming Soon' },
        { title: 'Coming Soon' },
        { title: 'Coming Soon' },
        { title: 'Coming Soon' },
        { title: 'Coming Soon' },
    ];

    return (
        <ContentWrapper>
            <div style={styles.tempContainer}>Shop Coming Soon!</div>
            <div className="temp-market-card-collection">
                {cardData.map((card, index) => (
                    <div className="temp-market-card-wrapper" key={index}>
                        <div className="temp-market-card">
                            <div className="temp-market-card-image"></div>
                            <div className="temp-market-content-box">
                                <div className="temp-market-card-title">{card.title}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </ContentWrapper>
    )
}

export default TempMarketContainer