// HomeContainer.jsx
import React from 'react';
import ContentWrapper from '../ContentWrapper';
import WorldsContent from "./WorldsContent";
import TabsContainer from './TabsContainer';
import EventsContent from './EventsContent';
import EventPageContent from './EventPage/EventPageContent';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import WorldPageContent from './WorldPage/WorldPageContent';

const HomeContainer = () => {

  return (
    <ContentWrapper width={"100%"}>
      {/* {!isDetailedPage && <TabsContainer onTabClick={handleTabClick} homeTab={activeTab} />} */}
      <Routes>
        <Route index element={<WorldsContent width={"100%"} />} />
        <Route path="events" element={<EventsContent width={"100%"} />} />
        <Route path="events/:eventId" element={<EventPageContent width={"100%"}/>} />
        <Route path="spaces/:worldId" element={<WorldPageContent width={"100%"} />} />
        {/* You can add more nested routes here if necessary */}
      </Routes>
    </ContentWrapper>
  );
};

export default HomeContainer;
