import React, { useRef, useState, useEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { Html } from '@react-three/drei';
import { useContext } from 'react';
import { GlobalContext } from '../../contexts/GlobalStore';

const Marker = ({ parentRef, position, rotation, url, title, description }) => {
  const markerRef = useRef();
  const ringRef = useRef();
  const [clicked, setClicked] = useState(false);

  const {isMobileView} = useContext(GlobalContext)

  const handleClick = () => setClicked(!clicked);
  
  const handleHoverStart = () => {
    document.body.style.cursor = 'pointer'; // Change cursor to pointer on hover
  };

  const handleHoverEnd = () => {
    document.body.style.cursor = 'default'; // Revert cursor to default
  };

  const handleClose = () => {
    setClicked(false);
  };

  useFrame(() => {
    if (ringRef.current) {
      const elapsed = performance.now() / 1000;
      const newScale = 0.2 + 0.03 * Math.sin(elapsed * 3);
      ringRef.current.scale.set(newScale, newScale, newScale);
      const newOpacity = 0.5 + 0.3 * Math.cos(elapsed * 3);
      ringRef.current.material.opacity = newOpacity;
    }
  });

  useEffect(() => {
    if (parentRef.current && markerRef.current) {
      parentRef.current.add(markerRef.current);
      console.log(`Marker added to group: ${parentRef.current.uuid}`);
    }

    return () => {
      if (parentRef.current && markerRef.current) {
        parentRef.current.remove(markerRef.current);
        console.log(`Marker removed from group: ${markerRef.current.uuid}`);
      }
    };
  }, [parentRef]);

  return (
    <group ref={markerRef} position={position}>
      <mesh
        ref={ringRef}
        rotation={rotation}
        scale={[0.3, 0.3, 0.3]}
      >
        <torusGeometry args={[0.5, 0.07, 16, 100]} />
        <meshBasicMaterial color="#7BB66C" transparent opacity={0.5} />
      </mesh>

      <mesh
        onPointerOver={handleHoverStart}
        onPointerOut={handleHoverEnd}
        onClick={handleClick}
        scale={[0.15, 0.15, 0.15]}
      >
        <sphereGeometry args={[0.4, 16, 16]} />
        <meshStandardMaterial color="#ADFF98" />
      </mesh>

      {clicked && (
        <Html distanceFactor={10} position={[0, -0.05, 0]}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              padding: '8px 4px',
              borderRadius: '8px',
              fontSize: '12px',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              whiteSpace: 'normal',
              pointerEvents: 'auto',
              transform: 'translateX(-50%)',
              textAlign: 'center',
              minWidth: isMobileView ? '120px' : '220px',
              maxWidth: isMobileView ? '180px' : '300px',
              width: 'auto',
              wordWrap: 'break-word',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <button
                onClick={handleClose}
                style={{
                  background: 'transparent',
                  border: 'none',
                  color: 'white',
                  fontSize: isMobileView ? '24px' : '16px',
                  lineHeight: '16px',
                  cursor: 'pointer',
                  padding: '0px'
                }}
              >
                ✕
              </button>
            </div>
            <div style={{ textAlign: 'center', wordBreak: 'break-word' }}>
              <div style={{ fontFamily: 'Work Sans', fontWeight: '600' }}>{title}</div>
              <div>
                <div>{description}</div>
              </div>
              <button onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}
                style={{
                  marginTop: '8px',
                  padding: '4px 8px',
                  backgroundColor: '#FFF',
                  color: 'black',
                  border: 'none',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  fontFamily: 'Work Sans'
                }}
              >
                Enter
              </button>
            </div>
          </div>
        </Html>
      )}
    </group>
  );
};

export default Marker;
