import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import { DataStore, AuthModeStrategyType } from 'aws-amplify/datastore';
import awsExports from './aws-exports';
import { GlobalProvider } from "./contexts/GlobalStore";
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { CookieStorage } from 'aws-amplify/utils';
import { ThemeProvider, createTheme } from "@aws-amplify/ui-react";
import { studioTheme } from './ui-components';
import PasswordPage from './ui-containers/PasswordPage';

const updatedTheme = createTheme({
  name: "my-theme-updates", 
  tokens: {
    components: {
      button: {
        primary: {
          backgroundColor: {
            value: "#1f1f1f"
          },
        },
      },
    },
  },
}, studioTheme)

Amplify.configure(awsExports);

DataStore.configure({
  authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({
  domain: '.triber.space', 
  secure: true 
}));

const root = ReactDOM.createRoot(document.getElementById('root'));

const RootComponent = () => {
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);

  return (
    <React.StrictMode>
      <BrowserRouter>
        <GlobalProvider>
          <ThemeProvider theme={updatedTheme}>
            <App/>
          </ThemeProvider>
        </GlobalProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
};

root.render(<RootComponent />);

reportWebVitals();
