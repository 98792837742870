/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAvatar = /* GraphQL */ `
  mutation CreateAvatar(
    $input: CreateAvatarInput!
    $condition: ModelAvatarConditionInput
  ) {
    createAvatar(input: $input, condition: $condition) {
      id
      Profile {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          Profile {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        following {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connections {
          items {
            id
            user1Id
            user2Id
            createdAt
            updatedAt
            profileConnectionsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        pointsRecords {
          items {
            id
            userId
            reason
            amount
            createdAt
            updatedAt
            profilePointsRecordsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        totalPoints
        inventory {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      model
      createdAt
      updatedAt
      avatarProfileId
      owner
      __typename
    }
  }
`;
export const updateAvatar = /* GraphQL */ `
  mutation UpdateAvatar(
    $input: UpdateAvatarInput!
    $condition: ModelAvatarConditionInput
  ) {
    updateAvatar(input: $input, condition: $condition) {
      id
      Profile {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          Profile {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        following {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connections {
          items {
            id
            user1Id
            user2Id
            createdAt
            updatedAt
            profileConnectionsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        pointsRecords {
          items {
            id
            userId
            reason
            amount
            createdAt
            updatedAt
            profilePointsRecordsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        totalPoints
        inventory {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      model
      createdAt
      updatedAt
      avatarProfileId
      owner
      __typename
    }
  }
`;
export const deleteAvatar = /* GraphQL */ `
  mutation DeleteAvatar(
    $input: DeleteAvatarInput!
    $condition: ModelAvatarConditionInput
  ) {
    deleteAvatar(input: $input, condition: $condition) {
      id
      Profile {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          Profile {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        following {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connections {
          items {
            id
            user1Id
            user2Id
            createdAt
            updatedAt
            profileConnectionsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        pointsRecords {
          items {
            id
            userId
            reason
            amount
            createdAt
            updatedAt
            profilePointsRecordsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        totalPoints
        inventory {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      model
      createdAt
      updatedAt
      avatarProfileId
      owner
      __typename
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile(
    $input: CreateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    createProfile(input: $input, condition: $condition) {
      id
      username
      name
      instagram
      twitter
      Avatar {
        id
        Profile {
          id
          username
          name
          instagram
          twitter
          Avatar {
            id
            model
            createdAt
            updatedAt
            avatarProfileId
            owner
            __typename
          }
          bio
          baseAvatarColor
          followers {
            nextToken
            __typename
          }
          following {
            nextToken
            __typename
          }
          connections {
            nextToken
            __typename
          }
          pointsRecords {
            nextToken
            __typename
          }
          totalPoints
          inventory {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          profileAvatarId
          owner
          __typename
        }
        model
        createdAt
        updatedAt
        avatarProfileId
        owner
        __typename
      }
      bio
      baseAvatarColor
      followers {
        items {
          id
          follower {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          followee {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          followerId
          followeeId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      following {
        items {
          id
          follower {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          followee {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          followerId
          followeeId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      connections {
        items {
          id
          user1 {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          user1Id
          user2 {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          user2Id
          createdAt
          updatedAt
          profileConnectionsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      pointsRecords {
        items {
          id
          user {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          userId
          reason
          amount
          createdAt
          updatedAt
          profilePointsRecordsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      totalPoints
      inventory {
        items {
          id
          owner {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          ownerId
          product {
            id
            creatorID
            itemName
            stripeProductId
            description
            price
            tribePointsPrice
            imageUrl
            stock
            productType
            glbUrl
            createdAt
            updatedAt
            creatorProductsId
            owner
            __typename
          }
          productId
          acquiredDate
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      profileAvatarId
      owner
      __typename
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile(
    $input: UpdateProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    updateProfile(input: $input, condition: $condition) {
      id
      username
      name
      instagram
      twitter
      Avatar {
        id
        Profile {
          id
          username
          name
          instagram
          twitter
          Avatar {
            id
            model
            createdAt
            updatedAt
            avatarProfileId
            owner
            __typename
          }
          bio
          baseAvatarColor
          followers {
            nextToken
            __typename
          }
          following {
            nextToken
            __typename
          }
          connections {
            nextToken
            __typename
          }
          pointsRecords {
            nextToken
            __typename
          }
          totalPoints
          inventory {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          profileAvatarId
          owner
          __typename
        }
        model
        createdAt
        updatedAt
        avatarProfileId
        owner
        __typename
      }
      bio
      baseAvatarColor
      followers {
        items {
          id
          follower {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          followee {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          followerId
          followeeId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      following {
        items {
          id
          follower {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          followee {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          followerId
          followeeId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      connections {
        items {
          id
          user1 {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          user1Id
          user2 {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          user2Id
          createdAt
          updatedAt
          profileConnectionsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      pointsRecords {
        items {
          id
          user {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          userId
          reason
          amount
          createdAt
          updatedAt
          profilePointsRecordsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      totalPoints
      inventory {
        items {
          id
          owner {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          ownerId
          product {
            id
            creatorID
            itemName
            stripeProductId
            description
            price
            tribePointsPrice
            imageUrl
            stock
            productType
            glbUrl
            createdAt
            updatedAt
            creatorProductsId
            owner
            __typename
          }
          productId
          acquiredDate
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      profileAvatarId
      owner
      __typename
    }
  }
`;
export const deleteProfile = /* GraphQL */ `
  mutation DeleteProfile(
    $input: DeleteProfileInput!
    $condition: ModelProfileConditionInput
  ) {
    deleteProfile(input: $input, condition: $condition) {
      id
      username
      name
      instagram
      twitter
      Avatar {
        id
        Profile {
          id
          username
          name
          instagram
          twitter
          Avatar {
            id
            model
            createdAt
            updatedAt
            avatarProfileId
            owner
            __typename
          }
          bio
          baseAvatarColor
          followers {
            nextToken
            __typename
          }
          following {
            nextToken
            __typename
          }
          connections {
            nextToken
            __typename
          }
          pointsRecords {
            nextToken
            __typename
          }
          totalPoints
          inventory {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          profileAvatarId
          owner
          __typename
        }
        model
        createdAt
        updatedAt
        avatarProfileId
        owner
        __typename
      }
      bio
      baseAvatarColor
      followers {
        items {
          id
          follower {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          followee {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          followerId
          followeeId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      following {
        items {
          id
          follower {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          followee {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          followerId
          followeeId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      connections {
        items {
          id
          user1 {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          user1Id
          user2 {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          user2Id
          createdAt
          updatedAt
          profileConnectionsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      pointsRecords {
        items {
          id
          user {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          userId
          reason
          amount
          createdAt
          updatedAt
          profilePointsRecordsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      totalPoints
      inventory {
        items {
          id
          owner {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          ownerId
          product {
            id
            creatorID
            itemName
            stripeProductId
            description
            price
            tribePointsPrice
            imageUrl
            stock
            productType
            glbUrl
            createdAt
            updatedAt
            creatorProductsId
            owner
            __typename
          }
          productId
          acquiredDate
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      profileAvatarId
      owner
      __typename
    }
  }
`;
export const createFollow = /* GraphQL */ `
  mutation CreateFollow(
    $input: CreateFollowInput!
    $condition: ModelFollowConditionInput
  ) {
    createFollow(input: $input, condition: $condition) {
      id
      follower {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          Profile {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        following {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connections {
          items {
            id
            user1Id
            user2Id
            createdAt
            updatedAt
            profileConnectionsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        pointsRecords {
          items {
            id
            userId
            reason
            amount
            createdAt
            updatedAt
            profilePointsRecordsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        totalPoints
        inventory {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      followee {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          Profile {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        following {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connections {
          items {
            id
            user1Id
            user2Id
            createdAt
            updatedAt
            profileConnectionsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        pointsRecords {
          items {
            id
            userId
            reason
            amount
            createdAt
            updatedAt
            profilePointsRecordsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        totalPoints
        inventory {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      followerId
      followeeId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateFollow = /* GraphQL */ `
  mutation UpdateFollow(
    $input: UpdateFollowInput!
    $condition: ModelFollowConditionInput
  ) {
    updateFollow(input: $input, condition: $condition) {
      id
      follower {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          Profile {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        following {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connections {
          items {
            id
            user1Id
            user2Id
            createdAt
            updatedAt
            profileConnectionsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        pointsRecords {
          items {
            id
            userId
            reason
            amount
            createdAt
            updatedAt
            profilePointsRecordsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        totalPoints
        inventory {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      followee {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          Profile {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        following {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connections {
          items {
            id
            user1Id
            user2Id
            createdAt
            updatedAt
            profileConnectionsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        pointsRecords {
          items {
            id
            userId
            reason
            amount
            createdAt
            updatedAt
            profilePointsRecordsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        totalPoints
        inventory {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      followerId
      followeeId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteFollow = /* GraphQL */ `
  mutation DeleteFollow(
    $input: DeleteFollowInput!
    $condition: ModelFollowConditionInput
  ) {
    deleteFollow(input: $input, condition: $condition) {
      id
      follower {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          Profile {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        following {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connections {
          items {
            id
            user1Id
            user2Id
            createdAt
            updatedAt
            profileConnectionsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        pointsRecords {
          items {
            id
            userId
            reason
            amount
            createdAt
            updatedAt
            profilePointsRecordsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        totalPoints
        inventory {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      followee {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          Profile {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        following {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connections {
          items {
            id
            user1Id
            user2Id
            createdAt
            updatedAt
            profileConnectionsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        pointsRecords {
          items {
            id
            userId
            reason
            amount
            createdAt
            updatedAt
            profilePointsRecordsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        totalPoints
        inventory {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      followerId
      followeeId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createConnection = /* GraphQL */ `
  mutation CreateConnection(
    $input: CreateConnectionInput!
    $condition: ModelConnectionConditionInput
  ) {
    createConnection(input: $input, condition: $condition) {
      id
      user1 {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          Profile {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        following {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connections {
          items {
            id
            user1Id
            user2Id
            createdAt
            updatedAt
            profileConnectionsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        pointsRecords {
          items {
            id
            userId
            reason
            amount
            createdAt
            updatedAt
            profilePointsRecordsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        totalPoints
        inventory {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      user1Id
      user2 {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          Profile {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        following {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connections {
          items {
            id
            user1Id
            user2Id
            createdAt
            updatedAt
            profileConnectionsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        pointsRecords {
          items {
            id
            userId
            reason
            amount
            createdAt
            updatedAt
            profilePointsRecordsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        totalPoints
        inventory {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      user2Id
      createdAt
      updatedAt
      profileConnectionsId
      owner
      __typename
    }
  }
`;
export const updateConnection = /* GraphQL */ `
  mutation UpdateConnection(
    $input: UpdateConnectionInput!
    $condition: ModelConnectionConditionInput
  ) {
    updateConnection(input: $input, condition: $condition) {
      id
      user1 {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          Profile {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        following {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connections {
          items {
            id
            user1Id
            user2Id
            createdAt
            updatedAt
            profileConnectionsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        pointsRecords {
          items {
            id
            userId
            reason
            amount
            createdAt
            updatedAt
            profilePointsRecordsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        totalPoints
        inventory {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      user1Id
      user2 {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          Profile {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        following {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connections {
          items {
            id
            user1Id
            user2Id
            createdAt
            updatedAt
            profileConnectionsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        pointsRecords {
          items {
            id
            userId
            reason
            amount
            createdAt
            updatedAt
            profilePointsRecordsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        totalPoints
        inventory {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      user2Id
      createdAt
      updatedAt
      profileConnectionsId
      owner
      __typename
    }
  }
`;
export const deleteConnection = /* GraphQL */ `
  mutation DeleteConnection(
    $input: DeleteConnectionInput!
    $condition: ModelConnectionConditionInput
  ) {
    deleteConnection(input: $input, condition: $condition) {
      id
      user1 {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          Profile {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        following {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connections {
          items {
            id
            user1Id
            user2Id
            createdAt
            updatedAt
            profileConnectionsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        pointsRecords {
          items {
            id
            userId
            reason
            amount
            createdAt
            updatedAt
            profilePointsRecordsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        totalPoints
        inventory {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      user1Id
      user2 {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          Profile {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        following {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connections {
          items {
            id
            user1Id
            user2Id
            createdAt
            updatedAt
            profileConnectionsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        pointsRecords {
          items {
            id
            userId
            reason
            amount
            createdAt
            updatedAt
            profilePointsRecordsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        totalPoints
        inventory {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      user2Id
      createdAt
      updatedAt
      profileConnectionsId
      owner
      __typename
    }
  }
`;
export const createPoints = /* GraphQL */ `
  mutation CreatePoints(
    $input: CreatePointsInput!
    $condition: ModelPointsConditionInput
  ) {
    createPoints(input: $input, condition: $condition) {
      id
      user {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          Profile {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        following {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connections {
          items {
            id
            user1Id
            user2Id
            createdAt
            updatedAt
            profileConnectionsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        pointsRecords {
          items {
            id
            userId
            reason
            amount
            createdAt
            updatedAt
            profilePointsRecordsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        totalPoints
        inventory {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      userId
      reason
      amount
      createdAt
      updatedAt
      profilePointsRecordsId
      owner
      __typename
    }
  }
`;
export const updatePoints = /* GraphQL */ `
  mutation UpdatePoints(
    $input: UpdatePointsInput!
    $condition: ModelPointsConditionInput
  ) {
    updatePoints(input: $input, condition: $condition) {
      id
      user {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          Profile {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        following {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connections {
          items {
            id
            user1Id
            user2Id
            createdAt
            updatedAt
            profileConnectionsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        pointsRecords {
          items {
            id
            userId
            reason
            amount
            createdAt
            updatedAt
            profilePointsRecordsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        totalPoints
        inventory {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      userId
      reason
      amount
      createdAt
      updatedAt
      profilePointsRecordsId
      owner
      __typename
    }
  }
`;
export const deletePoints = /* GraphQL */ `
  mutation DeletePoints(
    $input: DeletePointsInput!
    $condition: ModelPointsConditionInput
  ) {
    deletePoints(input: $input, condition: $condition) {
      id
      user {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          Profile {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        following {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connections {
          items {
            id
            user1Id
            user2Id
            createdAt
            updatedAt
            profileConnectionsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        pointsRecords {
          items {
            id
            userId
            reason
            amount
            createdAt
            updatedAt
            profilePointsRecordsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        totalPoints
        inventory {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      userId
      reason
      amount
      createdAt
      updatedAt
      profilePointsRecordsId
      owner
      __typename
    }
  }
`;
export const createCreator = /* GraphQL */ `
  mutation CreateCreator(
    $input: CreateCreatorInput!
    $condition: ModelCreatorConditionInput
  ) {
    createCreator(input: $input, condition: $condition) {
      id
      World {
        id
        name
        Spaces {
          items {
            id
            title
            wID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Creator {
          id
          World {
            id
            name
            createdAt
            updatedAt
            worldCreatorId
            __typename
          }
          Products {
            nextToken
            __typename
          }
          name
          createdAt
          updatedAt
          creatorWorldId
          __typename
        }
        createdAt
        updatedAt
        worldCreatorId
        __typename
      }
      Products {
        items {
          id
          creatorID
          creator {
            id
            name
            createdAt
            updatedAt
            creatorWorldId
            __typename
          }
          itemName
          stripeProductId
          description
          price
          tribePointsPrice
          imageUrl
          stock
          inventoryItems {
            nextToken
            __typename
          }
          productType
          glbUrl
          createdAt
          updatedAt
          creatorProductsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      name
      createdAt
      updatedAt
      creatorWorldId
      __typename
    }
  }
`;
export const updateCreator = /* GraphQL */ `
  mutation UpdateCreator(
    $input: UpdateCreatorInput!
    $condition: ModelCreatorConditionInput
  ) {
    updateCreator(input: $input, condition: $condition) {
      id
      World {
        id
        name
        Spaces {
          items {
            id
            title
            wID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Creator {
          id
          World {
            id
            name
            createdAt
            updatedAt
            worldCreatorId
            __typename
          }
          Products {
            nextToken
            __typename
          }
          name
          createdAt
          updatedAt
          creatorWorldId
          __typename
        }
        createdAt
        updatedAt
        worldCreatorId
        __typename
      }
      Products {
        items {
          id
          creatorID
          creator {
            id
            name
            createdAt
            updatedAt
            creatorWorldId
            __typename
          }
          itemName
          stripeProductId
          description
          price
          tribePointsPrice
          imageUrl
          stock
          inventoryItems {
            nextToken
            __typename
          }
          productType
          glbUrl
          createdAt
          updatedAt
          creatorProductsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      name
      createdAt
      updatedAt
      creatorWorldId
      __typename
    }
  }
`;
export const deleteCreator = /* GraphQL */ `
  mutation DeleteCreator(
    $input: DeleteCreatorInput!
    $condition: ModelCreatorConditionInput
  ) {
    deleteCreator(input: $input, condition: $condition) {
      id
      World {
        id
        name
        Spaces {
          items {
            id
            title
            wID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Creator {
          id
          World {
            id
            name
            createdAt
            updatedAt
            worldCreatorId
            __typename
          }
          Products {
            nextToken
            __typename
          }
          name
          createdAt
          updatedAt
          creatorWorldId
          __typename
        }
        createdAt
        updatedAt
        worldCreatorId
        __typename
      }
      Products {
        items {
          id
          creatorID
          creator {
            id
            name
            createdAt
            updatedAt
            creatorWorldId
            __typename
          }
          itemName
          stripeProductId
          description
          price
          tribePointsPrice
          imageUrl
          stock
          inventoryItems {
            nextToken
            __typename
          }
          productType
          glbUrl
          createdAt
          updatedAt
          creatorProductsId
          owner
          __typename
        }
        nextToken
        __typename
      }
      name
      createdAt
      updatedAt
      creatorWorldId
      __typename
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      creatorID
      creator {
        id
        World {
          id
          name
          Spaces {
            nextToken
            __typename
          }
          Creator {
            id
            name
            createdAt
            updatedAt
            creatorWorldId
            __typename
          }
          createdAt
          updatedAt
          worldCreatorId
          __typename
        }
        Products {
          items {
            id
            creatorID
            itemName
            stripeProductId
            description
            price
            tribePointsPrice
            imageUrl
            stock
            productType
            glbUrl
            createdAt
            updatedAt
            creatorProductsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        name
        createdAt
        updatedAt
        creatorWorldId
        __typename
      }
      itemName
      stripeProductId
      description
      price
      tribePointsPrice
      imageUrl
      stock
      inventoryItems {
        items {
          id
          owner {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          ownerId
          product {
            id
            creatorID
            itemName
            stripeProductId
            description
            price
            tribePointsPrice
            imageUrl
            stock
            productType
            glbUrl
            createdAt
            updatedAt
            creatorProductsId
            owner
            __typename
          }
          productId
          acquiredDate
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      productType
      glbUrl
      createdAt
      updatedAt
      creatorProductsId
      owner
      __typename
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      creatorID
      creator {
        id
        World {
          id
          name
          Spaces {
            nextToken
            __typename
          }
          Creator {
            id
            name
            createdAt
            updatedAt
            creatorWorldId
            __typename
          }
          createdAt
          updatedAt
          worldCreatorId
          __typename
        }
        Products {
          items {
            id
            creatorID
            itemName
            stripeProductId
            description
            price
            tribePointsPrice
            imageUrl
            stock
            productType
            glbUrl
            createdAt
            updatedAt
            creatorProductsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        name
        createdAt
        updatedAt
        creatorWorldId
        __typename
      }
      itemName
      stripeProductId
      description
      price
      tribePointsPrice
      imageUrl
      stock
      inventoryItems {
        items {
          id
          owner {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          ownerId
          product {
            id
            creatorID
            itemName
            stripeProductId
            description
            price
            tribePointsPrice
            imageUrl
            stock
            productType
            glbUrl
            createdAt
            updatedAt
            creatorProductsId
            owner
            __typename
          }
          productId
          acquiredDate
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      productType
      glbUrl
      createdAt
      updatedAt
      creatorProductsId
      owner
      __typename
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      creatorID
      creator {
        id
        World {
          id
          name
          Spaces {
            nextToken
            __typename
          }
          Creator {
            id
            name
            createdAt
            updatedAt
            creatorWorldId
            __typename
          }
          createdAt
          updatedAt
          worldCreatorId
          __typename
        }
        Products {
          items {
            id
            creatorID
            itemName
            stripeProductId
            description
            price
            tribePointsPrice
            imageUrl
            stock
            productType
            glbUrl
            createdAt
            updatedAt
            creatorProductsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        name
        createdAt
        updatedAt
        creatorWorldId
        __typename
      }
      itemName
      stripeProductId
      description
      price
      tribePointsPrice
      imageUrl
      stock
      inventoryItems {
        items {
          id
          owner {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          ownerId
          product {
            id
            creatorID
            itemName
            stripeProductId
            description
            price
            tribePointsPrice
            imageUrl
            stock
            productType
            glbUrl
            createdAt
            updatedAt
            creatorProductsId
            owner
            __typename
          }
          productId
          acquiredDate
          status
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      productType
      glbUrl
      createdAt
      updatedAt
      creatorProductsId
      owner
      __typename
    }
  }
`;
export const createInventoryItem = /* GraphQL */ `
  mutation CreateInventoryItem(
    $input: CreateInventoryItemInput!
    $condition: ModelInventoryItemConditionInput
  ) {
    createInventoryItem(input: $input, condition: $condition) {
      id
      owner {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          Profile {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        following {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connections {
          items {
            id
            user1Id
            user2Id
            createdAt
            updatedAt
            profileConnectionsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        pointsRecords {
          items {
            id
            userId
            reason
            amount
            createdAt
            updatedAt
            profilePointsRecordsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        totalPoints
        inventory {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      ownerId
      product {
        id
        creatorID
        creator {
          id
          World {
            id
            name
            createdAt
            updatedAt
            worldCreatorId
            __typename
          }
          Products {
            nextToken
            __typename
          }
          name
          createdAt
          updatedAt
          creatorWorldId
          __typename
        }
        itemName
        stripeProductId
        description
        price
        tribePointsPrice
        imageUrl
        stock
        inventoryItems {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        productType
        glbUrl
        createdAt
        updatedAt
        creatorProductsId
        owner
        __typename
      }
      productId
      acquiredDate
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInventoryItem = /* GraphQL */ `
  mutation UpdateInventoryItem(
    $input: UpdateInventoryItemInput!
    $condition: ModelInventoryItemConditionInput
  ) {
    updateInventoryItem(input: $input, condition: $condition) {
      id
      owner {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          Profile {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        following {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connections {
          items {
            id
            user1Id
            user2Id
            createdAt
            updatedAt
            profileConnectionsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        pointsRecords {
          items {
            id
            userId
            reason
            amount
            createdAt
            updatedAt
            profilePointsRecordsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        totalPoints
        inventory {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      ownerId
      product {
        id
        creatorID
        creator {
          id
          World {
            id
            name
            createdAt
            updatedAt
            worldCreatorId
            __typename
          }
          Products {
            nextToken
            __typename
          }
          name
          createdAt
          updatedAt
          creatorWorldId
          __typename
        }
        itemName
        stripeProductId
        description
        price
        tribePointsPrice
        imageUrl
        stock
        inventoryItems {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        productType
        glbUrl
        createdAt
        updatedAt
        creatorProductsId
        owner
        __typename
      }
      productId
      acquiredDate
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInventoryItem = /* GraphQL */ `
  mutation DeleteInventoryItem(
    $input: DeleteInventoryItemInput!
    $condition: ModelInventoryItemConditionInput
  ) {
    deleteInventoryItem(input: $input, condition: $condition) {
      id
      owner {
        id
        username
        name
        instagram
        twitter
        Avatar {
          id
          Profile {
            id
            username
            name
            instagram
            twitter
            bio
            baseAvatarColor
            totalPoints
            createdAt
            updatedAt
            profileAvatarId
            owner
            __typename
          }
          model
          createdAt
          updatedAt
          avatarProfileId
          owner
          __typename
        }
        bio
        baseAvatarColor
        followers {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        following {
          items {
            id
            followerId
            followeeId
            createdAt
            updatedAt
            owner
            __typename
          }
          nextToken
          __typename
        }
        connections {
          items {
            id
            user1Id
            user2Id
            createdAt
            updatedAt
            profileConnectionsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        pointsRecords {
          items {
            id
            userId
            reason
            amount
            createdAt
            updatedAt
            profilePointsRecordsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        totalPoints
        inventory {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        profileAvatarId
        owner
        __typename
      }
      ownerId
      product {
        id
        creatorID
        creator {
          id
          World {
            id
            name
            createdAt
            updatedAt
            worldCreatorId
            __typename
          }
          Products {
            nextToken
            __typename
          }
          name
          createdAt
          updatedAt
          creatorWorldId
          __typename
        }
        itemName
        stripeProductId
        description
        price
        tribePointsPrice
        imageUrl
        stock
        inventoryItems {
          items {
            id
            ownerId
            productId
            acquiredDate
            status
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        productType
        glbUrl
        createdAt
        updatedAt
        creatorProductsId
        owner
        __typename
      }
      productId
      acquiredDate
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      productID
      userID
      paymentMethod
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      productID
      userID
      paymentMethod
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      productID
      userID
      paymentMethod
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWorld = /* GraphQL */ `
  mutation CreateWorld(
    $input: CreateWorldInput!
    $condition: ModelWorldConditionInput
  ) {
    createWorld(input: $input, condition: $condition) {
      id
      name
      Spaces {
        items {
          id
          title
          wID
          World {
            id
            name
            createdAt
            updatedAt
            worldCreatorId
            __typename
          }
          Events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Creator {
        id
        World {
          id
          name
          Spaces {
            nextToken
            __typename
          }
          Creator {
            id
            name
            createdAt
            updatedAt
            creatorWorldId
            __typename
          }
          createdAt
          updatedAt
          worldCreatorId
          __typename
        }
        Products {
          items {
            id
            creatorID
            itemName
            stripeProductId
            description
            price
            tribePointsPrice
            imageUrl
            stock
            productType
            glbUrl
            createdAt
            updatedAt
            creatorProductsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        name
        createdAt
        updatedAt
        creatorWorldId
        __typename
      }
      createdAt
      updatedAt
      worldCreatorId
      __typename
    }
  }
`;
export const updateWorld = /* GraphQL */ `
  mutation UpdateWorld(
    $input: UpdateWorldInput!
    $condition: ModelWorldConditionInput
  ) {
    updateWorld(input: $input, condition: $condition) {
      id
      name
      Spaces {
        items {
          id
          title
          wID
          World {
            id
            name
            createdAt
            updatedAt
            worldCreatorId
            __typename
          }
          Events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Creator {
        id
        World {
          id
          name
          Spaces {
            nextToken
            __typename
          }
          Creator {
            id
            name
            createdAt
            updatedAt
            creatorWorldId
            __typename
          }
          createdAt
          updatedAt
          worldCreatorId
          __typename
        }
        Products {
          items {
            id
            creatorID
            itemName
            stripeProductId
            description
            price
            tribePointsPrice
            imageUrl
            stock
            productType
            glbUrl
            createdAt
            updatedAt
            creatorProductsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        name
        createdAt
        updatedAt
        creatorWorldId
        __typename
      }
      createdAt
      updatedAt
      worldCreatorId
      __typename
    }
  }
`;
export const deleteWorld = /* GraphQL */ `
  mutation DeleteWorld(
    $input: DeleteWorldInput!
    $condition: ModelWorldConditionInput
  ) {
    deleteWorld(input: $input, condition: $condition) {
      id
      name
      Spaces {
        items {
          id
          title
          wID
          World {
            id
            name
            createdAt
            updatedAt
            worldCreatorId
            __typename
          }
          Events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      Creator {
        id
        World {
          id
          name
          Spaces {
            nextToken
            __typename
          }
          Creator {
            id
            name
            createdAt
            updatedAt
            creatorWorldId
            __typename
          }
          createdAt
          updatedAt
          worldCreatorId
          __typename
        }
        Products {
          items {
            id
            creatorID
            itemName
            stripeProductId
            description
            price
            tribePointsPrice
            imageUrl
            stock
            productType
            glbUrl
            createdAt
            updatedAt
            creatorProductsId
            owner
            __typename
          }
          nextToken
          __typename
        }
        name
        createdAt
        updatedAt
        creatorWorldId
        __typename
      }
      createdAt
      updatedAt
      worldCreatorId
      __typename
    }
  }
`;
export const createSpace = /* GraphQL */ `
  mutation CreateSpace(
    $input: CreateSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    createSpace(input: $input, condition: $condition) {
      id
      title
      wID
      World {
        id
        name
        Spaces {
          items {
            id
            title
            wID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Creator {
          id
          World {
            id
            name
            createdAt
            updatedAt
            worldCreatorId
            __typename
          }
          Products {
            nextToken
            __typename
          }
          name
          createdAt
          updatedAt
          creatorWorldId
          __typename
        }
        createdAt
        updatedAt
        worldCreatorId
        __typename
      }
      Events {
        items {
          id
          name
          type
          spaceID
          Comments {
            nextToken
            __typename
          }
          World {
            id
            name
            createdAt
            updatedAt
            worldCreatorId
            __typename
          }
          Space {
            id
            title
            wID
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          eventWorldId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSpace = /* GraphQL */ `
  mutation UpdateSpace(
    $input: UpdateSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    updateSpace(input: $input, condition: $condition) {
      id
      title
      wID
      World {
        id
        name
        Spaces {
          items {
            id
            title
            wID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Creator {
          id
          World {
            id
            name
            createdAt
            updatedAt
            worldCreatorId
            __typename
          }
          Products {
            nextToken
            __typename
          }
          name
          createdAt
          updatedAt
          creatorWorldId
          __typename
        }
        createdAt
        updatedAt
        worldCreatorId
        __typename
      }
      Events {
        items {
          id
          name
          type
          spaceID
          Comments {
            nextToken
            __typename
          }
          World {
            id
            name
            createdAt
            updatedAt
            worldCreatorId
            __typename
          }
          Space {
            id
            title
            wID
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          eventWorldId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSpace = /* GraphQL */ `
  mutation DeleteSpace(
    $input: DeleteSpaceInput!
    $condition: ModelSpaceConditionInput
  ) {
    deleteSpace(input: $input, condition: $condition) {
      id
      title
      wID
      World {
        id
        name
        Spaces {
          items {
            id
            title
            wID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Creator {
          id
          World {
            id
            name
            createdAt
            updatedAt
            worldCreatorId
            __typename
          }
          Products {
            nextToken
            __typename
          }
          name
          createdAt
          updatedAt
          creatorWorldId
          __typename
        }
        createdAt
        updatedAt
        worldCreatorId
        __typename
      }
      Events {
        items {
          id
          name
          type
          spaceID
          Comments {
            nextToken
            __typename
          }
          World {
            id
            name
            createdAt
            updatedAt
            worldCreatorId
            __typename
          }
          Space {
            id
            title
            wID
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          eventWorldId
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      name
      type
      spaceID
      Comments {
        items {
          id
          content
          eventID
          Event {
            id
            name
            type
            spaceID
            createdAt
            updatedAt
            eventWorldId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      World {
        id
        name
        Spaces {
          items {
            id
            title
            wID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Creator {
          id
          World {
            id
            name
            createdAt
            updatedAt
            worldCreatorId
            __typename
          }
          Products {
            nextToken
            __typename
          }
          name
          createdAt
          updatedAt
          creatorWorldId
          __typename
        }
        createdAt
        updatedAt
        worldCreatorId
        __typename
      }
      Space {
        id
        title
        wID
        World {
          id
          name
          Spaces {
            nextToken
            __typename
          }
          Creator {
            id
            name
            createdAt
            updatedAt
            creatorWorldId
            __typename
          }
          createdAt
          updatedAt
          worldCreatorId
          __typename
        }
        Events {
          items {
            id
            name
            type
            spaceID
            createdAt
            updatedAt
            eventWorldId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      eventWorldId
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      name
      type
      spaceID
      Comments {
        items {
          id
          content
          eventID
          Event {
            id
            name
            type
            spaceID
            createdAt
            updatedAt
            eventWorldId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      World {
        id
        name
        Spaces {
          items {
            id
            title
            wID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Creator {
          id
          World {
            id
            name
            createdAt
            updatedAt
            worldCreatorId
            __typename
          }
          Products {
            nextToken
            __typename
          }
          name
          createdAt
          updatedAt
          creatorWorldId
          __typename
        }
        createdAt
        updatedAt
        worldCreatorId
        __typename
      }
      Space {
        id
        title
        wID
        World {
          id
          name
          Spaces {
            nextToken
            __typename
          }
          Creator {
            id
            name
            createdAt
            updatedAt
            creatorWorldId
            __typename
          }
          createdAt
          updatedAt
          worldCreatorId
          __typename
        }
        Events {
          items {
            id
            name
            type
            spaceID
            createdAt
            updatedAt
            eventWorldId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      eventWorldId
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      name
      type
      spaceID
      Comments {
        items {
          id
          content
          eventID
          Event {
            id
            name
            type
            spaceID
            createdAt
            updatedAt
            eventWorldId
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      World {
        id
        name
        Spaces {
          items {
            id
            title
            wID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        Creator {
          id
          World {
            id
            name
            createdAt
            updatedAt
            worldCreatorId
            __typename
          }
          Products {
            nextToken
            __typename
          }
          name
          createdAt
          updatedAt
          creatorWorldId
          __typename
        }
        createdAt
        updatedAt
        worldCreatorId
        __typename
      }
      Space {
        id
        title
        wID
        World {
          id
          name
          Spaces {
            nextToken
            __typename
          }
          Creator {
            id
            name
            createdAt
            updatedAt
            creatorWorldId
            __typename
          }
          createdAt
          updatedAt
          worldCreatorId
          __typename
        }
        Events {
          items {
            id
            name
            type
            spaceID
            createdAt
            updatedAt
            eventWorldId
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      eventWorldId
      __typename
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      content
      eventID
      Event {
        id
        name
        type
        spaceID
        Comments {
          items {
            id
            content
            eventID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        World {
          id
          name
          Spaces {
            nextToken
            __typename
          }
          Creator {
            id
            name
            createdAt
            updatedAt
            creatorWorldId
            __typename
          }
          createdAt
          updatedAt
          worldCreatorId
          __typename
        }
        Space {
          id
          title
          wID
          World {
            id
            name
            createdAt
            updatedAt
            worldCreatorId
            __typename
          }
          Events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        eventWorldId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      content
      eventID
      Event {
        id
        name
        type
        spaceID
        Comments {
          items {
            id
            content
            eventID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        World {
          id
          name
          Spaces {
            nextToken
            __typename
          }
          Creator {
            id
            name
            createdAt
            updatedAt
            creatorWorldId
            __typename
          }
          createdAt
          updatedAt
          worldCreatorId
          __typename
        }
        Space {
          id
          title
          wID
          World {
            id
            name
            createdAt
            updatedAt
            worldCreatorId
            __typename
          }
          Events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        eventWorldId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      content
      eventID
      Event {
        id
        name
        type
        spaceID
        Comments {
          items {
            id
            content
            eventID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        World {
          id
          name
          Spaces {
            nextToken
            __typename
          }
          Creator {
            id
            name
            createdAt
            updatedAt
            creatorWorldId
            __typename
          }
          createdAt
          updatedAt
          worldCreatorId
          __typename
        }
        Space {
          id
          title
          wID
          World {
            id
            name
            createdAt
            updatedAt
            worldCreatorId
            __typename
          }
          Events {
            nextToken
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        eventWorldId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
