import React, { useContext, useEffect } from 'react';
import ContentWrapper from '../ContentWrapper';
import ProfilePage from './ProfilePage';
import ProfileEmpty from './ProfileEmpty';
import { GlobalContext } from '../../contexts/GlobalStore';

const ProfileContainer = (props) => {
  const { isAuth, overrides, ...rest } = props;
  console.log("isAuth", isAuth)

  return (
    <ContentWrapper width={"100%"}>
      {isAuth ? <ProfilePage/> : <ProfileEmpty/>}
    </ContentWrapper>
  );
};

export default ProfileContainer;
